import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import React, { useEffect, ReactElement } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from '@mui/material'

import { yupResolver } from '@hookform/resolvers/yup'

import { config } from 'src/config/config'
import yupLocales from 'src/config/yupLocales'
import { Project } from 'src/types'
import { postRequest, patchRequest } from 'src/utils/requests'

import { useProjectStore } from '../IfcImporter/stores/projectStore'

yup.setLocale(yupLocales)

type DialogMode = 'create' | 'edit' | 'transfer'

interface ProjectFormData {
  name: string
  owner_guid?: string
}

const validationSchema = yup.object().shape({
  name: yup.string().min(5).max(100),
  // build in functionality of yup '.notRequired()' not working here
  // see: https://github.com/jquense/yup/issues/1267
  location: yup.string().matches(/.{5,100}/, {
    excludeEmptyString: true,
    message: 'Must be 5 characters',
  }),
  construction_project: yup.string().matches(/.{5,100}/, {
    excludeEmptyString: true,
    message: 'Must be 5 characters',
  }),
  owner_guid: yup.string().when('$mode', {
    is: 'transfer',
    then: schema => schema.required('Owner ID wird benötigt'),
    otherwise: schema => schema.optional(),
  }),
})

export function ProjectDialog({
  isOpen,
  onClose,
  mode = 'create',
  project = null,
}: {
  isOpen: boolean
  onClose: () => void
  mode?: DialogMode
  project?: Project | null
}): ReactElement {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const setProject = useProjectStore(state => state.setProject)

  const isEditMode = mode === 'edit'
  const isTransferMode = mode === 'transfer'

  let dialogTitle = 'Neues Projekt erstellen'
  if (isEditMode) dialogTitle = 'Projekt bearbeiten'
  if (isTransferMode) dialogTitle = 'Projekteigentümer übertragen (das Projekt wird vorher kopiert)'

  let submitButtonText = 'Erstellen'
  if (isEditMode) submitButtonText = 'Speichern'
  if (isTransferMode) submitButtonText = 'Kopieren und Übertragen'

  const {
    register: registerFormInput,
    handleSubmit: handleFormSubmit,
    formState: { errors: formErrors },
    reset: resetForm,
  } = useForm<ProjectFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues:
      isEditMode && project
        ? {
            name: project.name || '',
          }
        : isTransferMode && project
        ? {
            name: project.name || '',
            owner_guid: '',
          }
        : {},
    context: { mode },
  })

  // Reset form when dialog opens or changes mode/project
  useEffect(() => {
    if (isOpen) {
      resetForm(
        isEditMode && project
          ? {
              ...project,
            }
          : isTransferMode && project
          ? {
              ...project,
              owner_guid: '',
            }
          : {},
      )
    }
  }, [isOpen, resetForm, isEditMode, isTransferMode, project])

  const createProjectMutation = useMutation(
    (data: ProjectFormData) =>
      postRequest<Project>({
        url: config.apiRoutes.createProject,
        data,
      }),
    {
      onSuccess: ({ data }) => {
        onClose()
        setProject(data)
        history.push(`/ifc-importer/${data.guid}/step-1-upload`)
        queryClient.invalidateQueries('getRemainingProjects')
      },
      onError: error => {
        if ((error as AxiosError).response?.status === 402) {
          enqueueSnackbar('Keine Projekte mehr verfügbar', { variant: 'error' })
        } else {
          enqueueSnackbar('Fehler beim Erstellen des Projekts', { variant: 'error' })
        }
      },
    },
  )

  const editProjectMutation = useMutation(
    (data: ProjectFormData) =>
      patchRequest<Project>({
        url: config.apiRoutes.patchProject(project?.guid || ''),
        data,
      }),
    {
      onSuccess: () => {
        onClose()
        queryClient.invalidateQueries('getProjects')
        enqueueSnackbar('Projekt erfolgreich bearbeitet', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Bearbeiten des Projekts', { variant: 'error' })
      },
    },
  )

  const changeProjectOwnerMutation = useMutation(
    (data: ProjectFormData) =>
      postRequest<Project>({
        url: config.apiRoutes.postChangeProjectOwner(project?.guid || ''),
        data,
      }),
    {
      onSuccess: () => {
        onClose()
        queryClient.invalidateQueries('getProjects')
        enqueueSnackbar('Projekt erfolgreich übertragen', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Übertragen des Projekts', { variant: 'error' })
      },
    },
  )

  const handleFormSubmission = (data: ProjectFormData) => {
    if (isEditMode) {
      editProjectMutation.mutate(data)
    } else if (isTransferMode) {
      changeProjectOwnerMutation.mutate(data)
    } else {
      createProjectMutation.mutate(data)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        component="form"
        width={(theme: Theme) => theme.spacing(50)}
        noValidate
        autoComplete="off"
        onSubmit={handleFormSubmit(handleFormSubmission)}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent>
          <TextField
            {...registerFormInput('name')}
            label={'Projektname'}
            error={!!formErrors.name}
            helperText={formErrors.name?.message}
            fullWidth
            margin="dense"
            multiline
            disabled={isTransferMode}
          />

          {isTransferMode && (
            <TextField
              {...registerFormInput('owner_guid')}
              label={'Neue Eigentümer ID (auth0|...)'}
              error={!!formErrors.owner_guid}
              helperText={formErrors.owner_guid?.message}
              fullWidth
              margin="dense"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{'Abbrechen'}</Button>
          <Button
            loading={
              isEditMode
                ? editProjectMutation.isLoading
                : isTransferMode
                ? changeProjectOwnerMutation.isLoading
                : createProjectMutation.isLoading
            }
            variant="contained"
            type="submit"
          >
            {submitButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

// For backward compatibility
export const CreationDialog = ProjectDialog
