import { isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { v4 as uuidv4 } from 'uuid'

import React, { useEffect, ReactElement } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import Crop54 from '@mui/icons-material/Crop54'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Gite from '@mui/icons-material/Gite'
import OpenInFullOutlined from '@mui/icons-material/OpenInFullOutlined'
import PanToolOutlined from '@mui/icons-material/PanToolOutlined'
import {
  Typography,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Button,
  Divider,
  Alert,
} from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { config } from 'src/config/config'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { postRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../../../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../../../stores/editModelStore'
import { ChangeWallPlacement } from '../components/ChangeWallPlacement'
import { AddOpeningSubTab } from './AddOpeningSubTab'
import { ChangeWallLengthSubTab } from './ChangeWallLengthSubTab'
import { DrawWallSubTab } from './DrawWallSubTab'
import { MoveWallOrthogonalSubTab } from './MoveWallOrthogonalSubTab'
import { RemoveWallSubTab } from './RemoveWallSubTab'

export function EditModelTab(): ReactElement {
  const { enqueueSnackbar } = useSnackbar()

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const deselectPlanarElements = useGeneratedModelStore(state => state.deselectElements)
  const setCurrentModelPlanar = useGeneratedModelStore(state => state.setCurrentModelPlanar)

  const activeStorey = useEditModelStore(state => state.activeStorey)

  const setDrawnWall = useEditModelStore(state => state.setDrawnWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)
  const setEditedWall = useEditModelStore(state => state.setEditedWall)

  const editMode = useEditModelStore(state => state.editMode)
  const setEditMode = useEditModelStore(state => state.setEditMode)

  // ensure no wall is left drawn or edited when the user switches storeys
  useEffect(() => {
    setDrawnWall(undefined)
    setEditedWall(undefined)
  }, [activeStorey, deselectPlanarElements, setDrawnWall, setEditedWall])

  // set initial state based on selected tab
  useEffect(() => {
    if (editMode === 'draw') {
      setSelectedWall(undefined)
    } else {
      setDrawnWall(undefined)
    }
  }, [editMode, setDrawnWall, setSelectedWall])

  // ensure no active storey, drawn or selected wall is left on leave drawing mode
  useEffect(
    () => () => {
      setDrawnWall(undefined)
      setEditedWall(undefined)
      setSelectedWall(undefined)
    },
    [setDrawnWall, setEditedWall, setSelectedWall],
  )

  const { projectId } = useParams<{ projectId: string }>()

  const { mutateAsync: addRoofSlab, isLoading: isAddingRoofSlab } = useMutation(
    async () => {
      // Find the highest z coordinate from existing roof slabs
      let highestZ = 3.0

      if (currentModelPlanar && currentModelPlanar.roof_slabs) {
        // Iterate through all roof slabs and their points
        currentModelPlanar.roof_slabs.forEach(slab => {
          if (slab.shape && slab.shape.points) {
            slab.shape.points.forEach(point => {
              if (point.z > highestZ) {
                highestZ = point.z
              }
            })
          }
        })
      }

      // Create a 1x1 rectangle in the xy plane at the highest z
      const rectanglePoints = [
        { x: 0, y: 0, z: highestZ },
        { x: 1, y: 0, z: highestZ },
        { x: 1, y: 1, z: highestZ },
        { x: 0, y: 1, z: highestZ },
      ]

      const newModel = currentModelPlanar
        ? ({
            ...currentModelPlanar,
            roof_slabs: [
              ...currentModelPlanar.roof_slabs,
              {
                guid: uuidv4(),
                openings: [],
                shape: {
                  points: rectanglePoints,
                  guid: uuidv4(),
                },
              },
            ],
          } as PlanarModel)
        : null

      return (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postCurrentModelPlanar(projectId),
          data: newModel,
        })
      ).data
    },
    {
      onSuccess: currenModelResponse => {
        setCurrentModelPlanar(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Erstellen des neuen Daches', { variant: 'error' })
      },
    },
  )

  return currentModelPlanar ? (
    <CustomSidebar>
      <Stack spacing={2} mt={1}>
        {isUndefined(activeStorey) && (
          <Stack direction="column" spacing={2}>
            <Alert severity="info">
              Springe über den Floorplan Button in der Navbar oben rechts in die Grundrissansicht
            </Alert>
            <Divider />
            <Button variant="contained" onClick={() => addRoofSlab()} loading={isAddingRoofSlab}>
              Dachfläche hinzurügen
            </Button>
          </Stack>
        )}

        {!!activeStorey && (
          <>
            <ToggleButtonGroup size="small" fullWidth>
              <ToggleButton
                value="visible"
                size="small"
                onClick={() => setEditMode('delete')}
                selected={editMode === 'delete'}
              >
                <Tooltip title="Entfernen">
                  <DeleteOutlineIcon />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => setEditMode('draw')}
                selected={editMode === 'draw'}
              >
                <Tooltip title="Zeichnen">
                  <AddCircleOutline />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => setEditMode('change')}
                selected={editMode === 'change'}
              >
                <Tooltip title="Ändern">
                  <OpenInFullOutlined />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('move')
                }}
                selected={editMode === 'move'}
              >
                <Tooltip title="Wand verschieben">
                  <PanToolOutlined />
                </Tooltip>
              </ToggleButton>

              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('opening')
                }}
                selected={editMode === 'opening'}
              >
                <Tooltip title="Öffnung zeichnen">
                  <Crop54 />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value="active"
                size="small"
                onClick={() => {
                  setEditMode('placement')
                }}
                selected={editMode === 'placement'}
              >
                <Tooltip title="Wandplatzierung ändern">
                  <Gite />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>

            {editMode === 'delete' && <RemoveWallSubTab />}
            {editMode === 'draw' && <DrawWallSubTab />}
            {editMode === 'change' && <ChangeWallLengthSubTab />}
            {editMode === 'move' && <MoveWallOrthogonalSubTab />}
            {editMode === 'opening' && <AddOpeningSubTab />}
            {editMode === 'placement' && <ChangeWallPlacement />}
          </>
        )}
      </Stack>
    </CustomSidebar>
  ) : (
    <CustomSidebar>
      <Typography mt={1} mb={2}>
        <strong>Hinweis:</strong> Hier kannst du das Modell anpassen
      </Typography>
    </CustomSidebar>
  )
}
