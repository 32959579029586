import flatten from 'flat'
import cloneDeep from 'lodash-es/cloneDeep'

import React, { useState, useMemo, useLayoutEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import FilterAltOffOutlined from '@mui/icons-material/FilterAltOffOutlined'
import HighlightAlt from '@mui/icons-material/HighlightAlt'
import {
  Typography,
  Tabs,
  Tab,
  FormControl,
  TextField,
  MenuItem,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'
import { TabPanel } from 'src/components/generic/TabPanel'
import { capitalizeFirstLetter } from 'src/utils/misc'

import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { IfcElement } from '../../types'

export interface IfcElementsDetailsProps {
  elements: IfcElement[]
  selectableProperties?: boolean
  selectableChildren?: boolean
}

export function IfcElementsDetails({
  elements,
  selectableProperties = true,
  selectableChildren = true,
}: IfcElementsDetailsProps): ReactElement {
  const { t } = useTranslation(['ifcImporter'])

  const [tabIndex, setTabIndex] = useState(0)
  const [propertyGroupKey, setPropertyGroupKey] = useState<string | undefined>()

  const { reassignedIfcElements, searchableIfcElements } = useDerivedIfcData()
  const ifcOpenings = useIfcElementsStore(state => state.ifcOpenings)
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)

  // EFFECTS

  // switch back to the properties tab whenever the selected elements change
  useLayoutEffect(() => setTabIndex(0), [elements])

  // COMPUTE INTERSECTING PROPERTIES

  // a nested dict containing only properties that are shared (= equal nested
  // key + value) by the currently selected ifc elements. intersection operation
  // is already optimised for efficiency, see the comments below for details
  const intersectingElementProperties = useMemo(() => {
    // retrieve the already flattened properties and order them by size (dict
    // with least amount of properties first) in order to ensure that the
    // subsequent for...of loop breaks as early as possible
    const flatDictsToIntersect = elements
      .map(ifcElement => searchableIfcElements[ifcElement.PSET_data.id])
      .sort((searchableA, searchableB) => searchableA.flatSet.size - searchableB.flatSet.size)
      .map(searchableIfcElement => searchableIfcElement.flatDict)

    let intersectingFlatDict!: Record<string, string | number>

    // create a flat dict with all shared properties by iterating over
    // flatDictsToIntersect until A: no more matching properties are found
    // (which usually provides an early break) or B: the loop has ended
    for (const flatDict of flatDictsToIntersect) {
      // the first dict in the loop is the base to check against
      if (!intersectingFlatDict) {
        intersectingFlatDict = cloneDeep(flatDict)
        continue
      }

      // enables length checking and easy iteration using forEach
      const intersectedFlatDictAsArray = Object.entries(intersectingFlatDict)

      // break the loop if the result has no more properties to check against
      if (intersectedFlatDictAsArray.length < 1) break

      // remove any property not present in the current dict from the result,
      // thereby also reducing the properties to check against
      intersectedFlatDictAsArray.forEach(([key, value]) => {
        if (flatDict[key] !== value) delete intersectingFlatDict[key]
      })
    }

    // we currently (maybe naively) assume that this operation returns a dict
    // with just one level of nesting as this is what the backend returns
    // TODO: consider checking the shape of the returned dict
    return flatten.unflatten(intersectingFlatDict) as Record<
      string,
      Record<string, string | number>
    >
  }, [elements, searchableIfcElements])

  // the key is dependent on the available keys of the intersecting properties
  useLayoutEffect(() => {
    setPropertyGroupKey(Object.keys(intersectingElementProperties)[0])
  }, [intersectingElementProperties])

  // GET OPENINGS AND OTHER CHILDREN

  const openingElements = useMemo(() => {
    const openingIds = elements.reduce((collector, ifcElement) => {
      ifcOpenings?.[ifcElement.PSET_data.id]?.forEach(id => collector.add(id))
      return collector
    }, new Set() as Set<string>)

    return reassignedIfcElements?.filter(ifcElement => openingIds.has(ifcElement.PSET_data.id))
  }, [elements, reassignedIfcElements, ifcOpenings])

  const otherChildElements = useMemo(() => {
    const childIds = elements.reduce((collector, ifcElement) => {
      trueIfcGroups[ifcElement.PSET_data.id]?.forEach(id => collector.add(id))
      return collector
    }, new Set() as Set<string>)

    return reassignedIfcElements?.filter(ifcElement => childIds.has(ifcElement.PSET_data.id))
  }, [elements, reassignedIfcElements, trueIfcGroups])

  // RENDER

  return (
    <MuiBox border={1} borderColor="grey.200" borderRadius={1}>
      <Tabs
        value={tabIndex}
        onChange={(_: unknown, index: number) => setTabIndex(index)}
        variant="fullWidth"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label={t('ifcImporter:byTypeSidebar.properties')} />
        <Tab label={t('ifcImporter:byTypeSidebar.subElements')} />
      </Tabs>

      {/* ELEMENT(S) PROPERTIES */}
      <TabPanel value={tabIndex} index={0} p={1}>
        {propertyGroupKey && intersectingElementProperties[propertyGroupKey] ? (
          <>
            {elements.length > 1 && (
              <Typography textAlign="center" mt={0.5} mb={1} fontStyle="italic" color="grey.500">
                {t('ifcImporter:byTypeSidebar.sharedProperties')} <strong>{elements.length}</strong>{' '}
                {t('ifcImporter:byTypeSidebar.elements')}
              </Typography>
            )}

            <FormControl sx={{ my: 1, width: '100%' }}>
              <TextField
                select
                label={t('ifcImporter:byTypeSidebar.groupProperties')}
                value={propertyGroupKey}
                onChange={event => setPropertyGroupKey(event.target.value)}
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50' }}
              >
                {Object.keys(intersectingElementProperties).map(key => (
                  <MenuItem value={key} key={key}>
                    {capitalizeFirstLetter(key.replace(/PSET_Pset_|PSET_/g, ''))}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <Stack>
              {Object.entries(intersectingElementProperties[propertyGroupKey]).map(
                ([property, value], index) => (
                  <IfcPropertyItem
                    key={property}
                    property={property}
                    value={value}
                    colored={!!(index % 2)}
                    searchKey={`${propertyGroupKey}.${property}.${value}`}
                    selectable={selectableProperties}
                  />
                ),
              )}
            </Stack>
          </>
        ) : (
          <Typography textAlign="center" my={1}>
            {t('ifcImporter:byTypeSidebar.noSharedPropertiesExist')}
          </Typography>
        )}
      </TabPanel>

      {/* ELEMENT(S) OPENINGS AND OTHER CHILDREN */}
      <TabPanel value={tabIndex} index={1} p={1}>
        <Stack spacing={0.5}>
          {!openingElements?.length && !otherChildElements?.length && (
            <Typography textAlign="center" my={1}>
              {t('ifcImporter:byTypeSidebar.noSubElementsExist')}
            </Typography>
          )}

          {!!openingElements?.length && (
            <>
              <Typography variant="h6">{t('ifcImporter:byTypeSidebar.openings')}:</Typography>
              {openingElements.map((openingElement, index) => (
                <SelectableChildItem
                  key={openingElement.PSET_data.id}
                  name={openingElement.PSET_data.Name}
                  id={openingElement.PSET_data.id}
                  colored={!(index % 2)}
                  selectable={selectableChildren}
                />
              ))}
            </>
          )}

          {!!otherChildElements?.length && (
            <>
              <Typography variant="h6">
                {t('ifcImporter:byTypeSidebar.furtherSubelements')}:
              </Typography>
              {otherChildElements.map((childElement, index) => (
                <SelectableChildItem
                  key={childElement.PSET_data.id}
                  name={childElement.PSET_data.Name}
                  id={childElement.PSET_data.id}
                  colored={!(index % 2)}
                  selectable={selectableChildren}
                />
              ))}
            </>
          )}
        </Stack>
      </TabPanel>
    </MuiBox>
  )
}

interface IfcPropertyItemProps {
  property: string
  value: string | number
  colored: boolean
  searchKey: string
  selectable?: boolean
}

function IfcPropertyItem({
  property,
  value,
  colored,
  searchKey,
  selectable = true,
}: IfcPropertyItemProps): ReactElement {
  const { searchableIfcElements, trueIfcGroups } = useDerivedIfcData()
  const selectGroupIds = useIfcElementsStore(state => state.selectGroupIds)
  const selectStandAloneIds = useIfcElementsStore(state => state.selectStandAloneIds)

  return (
    <Stack
      direction="row"
      pl={1}
      pr={0.5}
      spacing={1}
      bgcolor={colored ? 'grey.100' : 'white'}
      borderRadius={1}
      alignItems="center"
    >
      <Typography
        flexGrow={1}
        flexShrink={0}
        fontWeight="bold"
        color="grey.600"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {capitalizeFirstLetter(property)}
      </Typography>

      <Stack direction="row" spacing={0.5} alignItems="center" overflow="hidden">
        <Typography overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {value}
        </Typography>

        {selectable && (
          <IconButton
            onClick={() => {
              const matchingSortedIfcIds = Object.entries(searchableIfcElements)
                // find all matching strings
                .reduce(
                  (collector, [ifcId, searchStacks]) => {
                    if (searchStacks.flatSet.has(searchKey)) {
                      if (trueIfcGroups[ifcId]) {
                        collector.groupIds.push(ifcId)
                      } else {
                        collector.standAloneIds.push(ifcId)
                      }
                    }

                    return collector
                  },
                  { groupIds: [] as string[], standAloneIds: [] as string[] },
                )

              selectGroupIds(matchingSortedIfcIds.groupIds)
              selectStandAloneIds(matchingSortedIfcIds.standAloneIds)
            }}
            size="small"
          >
            <HighlightAlt sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

interface SelectableChildItemProps {
  name?: string
  id: string
  colored: boolean
  selectable?: boolean
}

function SelectableChildItem({
  name = 'Ohne Name',
  id,
  colored,
  selectable = true,
}: SelectableChildItemProps): ReactElement {
  const { mergedFilteredIds } = useDerivedIfcData()
  const deselectAllIds = useIfcElementsStore(state => state.deselectAllIds)
  const selectStandAloneIds = useIfcElementsStore(state => state.selectStandAloneIds)

  return (
    <Stack
      direction="row"
      p={0.5}
      pl={1}
      spacing={1}
      bgcolor={colored ? 'grey.100' : 'white'}
      borderRadius={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <div>
        <Typography fontWeight="bold" color="grey.600">
          {name}
        </Typography>
        <Typography variant="body2">Id: {id}</Typography>
      </div>

      {selectable && (
        <>
          {mergedFilteredIds.has(id) ? (
            <Tooltip title="Gefilterte Elemente können nicht ausgewählt werden">
              <MuiBox p={0.5} lineHeight={1}>
                <FilterAltOffOutlined sx={{ fontSize: 20 }} color="disabled" />
              </MuiBox>
            </Tooltip>
          ) : (
            <IconButton
              onClick={() => {
                deselectAllIds()
                selectStandAloneIds([id])
              }}
              size="small"
            >
              <HighlightAlt sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  )
}
