import { inRange } from 'lodash-es'
import { v4 } from 'uuid'

import NumberFormat from 'react-number-format'

import { GridRenderEditCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium'

import { getCenterMultiple } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const getCenterPoint = (p1: Point, p2: Point): Point & { pId: string } => {
  const p1Immutable = new ImmutableVector3(p1.x, p1.y, p1.z)
  const p2Immutable = new ImmutableVector3(p2.x, p2.y, p2.z)

  const center = getCenterMultiple([p1Immutable, p2Immutable])

  return {
    x: center.x,
    y: center.y,
    z: center.z,
    pId: v4(),
  }
}

export const isNearButNotEqual = (reference: number, other: number, precision = 0.01) =>
  reference !== other && inRange(other, reference - precision, reference + precision)

export const createCoordinateNumberInput = (field: 'x' | 'y' | 'z') => {
  // eslint-disable-next-line react/display-name
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: GridRenderEditCellParams<any, any, any, GridTreeNodeWithRender>,
  ) => (
    <NumberFormat
      type="text"
      onValueChange={async value => {
        await params.api.setEditCellValue({
          id: params.row.pId,
          field: field,
          value: value.floatValue,
        })
      }}
      value={params.value}
      thousandSeparator="."
      isNumericString
      decimalSeparator=","
      style={{ border: 'none', width: '100%' }}
      autoFocus
    />
  )
}
