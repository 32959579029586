import { isArray } from 'lodash-es'
import { Line3, Plane, Vector2, Vector3 } from 'three'
import { v4 } from 'uuid'

import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const generateDefaultValues = (points: Point[]) =>
  points.map(p => ({
    pId: v4(),
    ...p,
  }))

/**
 * Assumes the array is a loop. So e.g. if you try to access the last element +
 * 1 you will get the first array item
 */
export const nthLooped = <T>(array: Array<T>, index: number) => {
  if (index < 0) {
    const calculatedIndex = array.length - ((-index - 1) % array.length) - 1

    return array[calculatedIndex]
  }

  return array[index % array.length]
}

/**
 * This method will sort the provided points counter-clockwise. Only x and y
 * coordinates are used
 */
export const sortPointsCounterClockwise = (points: ImmutableVector3[] | Point[]) => {
  const center = { x: 0, y: 0, z: 0 }
  for (let i = 0; i < points.length; i++) {
    center.x += points[i].x
    center.y += points[i].y
  }
  center.x /= points.length
  center.y /= points.length
  const sortedPoints = [...points].sort((a, b) => {
    const angleA = Math.atan2(a.y - center.y, a.x - center.x)
    const angleB = Math.atan2(b.y - center.y, b.x - center.x)
    return angleA - angleB
  })
  return sortedPoints
}

/**
 * Returns the normal based on the first three points of provided array
 */
export const getPolygonNormal = (points: ImmutableVector3[]): ImmutableVector3 => {
  if (points.length < 3) throw new Error('At least 3 Points have to be provided')

  const norm = new ImmutableVector3()
    .crossVectors(points[1].sub(points[0]), points[2].sub(points[0]))
    .normalize()

  return norm
}

export const get2DLineOrthoDirection = (start: Vector2, end: Vector2): Vector2 => {
  const direction = start.clone().sub(end).normalize()

  // rotate 90 degrees around origin
  const rotated = direction.rotateAround(new Vector2(), Math.PI / 2)

  return rotated
}

export const toVector2 = (point: Point | Vector3): Vector2 => {
  if (isArray(point)) return new Vector2(...point)
  return new Vector2(point.x, point.y)
}

export const infiniteLineIntersection = (
  plane: Plane,
  lineStart: ImmutableVector3,
  lineEnd: ImmutableVector3,
) => {
  const lineDirection = lineStart.directionTo(lineEnd)

  // Calculate the dot product of the plane's normal and the line's direction
  const dotProduct = plane.normal.dot(lineDirection.v)

  // Check if the line is nearly parallel to the plane
  const parallelThreshold = 1e-6
  if (Math.abs(dotProduct) > parallelThreshold) {
    // Calculate the parameter at which the line intersects the plane
    const t = plane.normal.dot(plane.coplanarPoint(new Vector3()).sub(lineStart.v)) / dotProduct

    // Calculate the intersection point
    const intersectionPoint = lineStart.add(lineDirection.multiplyScalar(t))

    return intersectionPoint
  }

  return null
}

/**
 * projects point onto plane (in z Direction 0, 0, 1). Will only work for points on plane that are on z >= 0
 */
export const projectPointOntoPlane = (plane: Plane, pointToProject: ImmutableVector3) => {
  // if we use negative numbers for the line start here `intersectLine` below
  // will return points that are not exactly on the plane (using distanceToPlane
  // will return a distance > 0). Hence we limit this here to points between 0
  // and Number.MAX_VALUE
  const lineStart = new ImmutableVector3(pointToProject.x, pointToProject.y, 0)
  const lineEnd = new ImmutableVector3(pointToProject.x, pointToProject.y, Number.MAX_VALUE)

  const line = new Line3(lineStart.v, lineEnd.v)

  const intersectionPoint = plane.intersectLine(line, new Vector3())

  if (intersectionPoint === null) throw new Error('No intersection found')

  return toImmutable(intersectionPoint)
}

export const toImmutable2 = (p: Point) => new ImmutableVector3(p.x, p.y, p.z)

export const toVector3 = (p: Point) => new Vector3(p.x, p.y, p.z)
