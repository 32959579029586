import React, { ReactElement, useCallback } from 'react'

import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { roofStoreyKey } from 'src/config/misc'
import { useHiddenElementsStore } from 'src/pages/IfcImporter/stores/hiddenElementsIdsStore'
import {
  IfcElementsStoreState,
  useIfcElementsStore,
} from 'src/pages/IfcImporter/stores/ifcElementsStore'
import sceneColors from 'src/styles/sceneColors'

import { useGeneratedModelStore } from '../generatedModelStore'
import { ShapeMeshProps, ShapeMesh } from './ShapeMesh'

interface GeneratedPlanarModelMeshProps extends ShapeMeshProps {
  hiddenIdSelector: (state: IfcElementsStoreState, ifcId: string) => boolean
}

export function GeneratedModelMesh({
  data,
  hiddenIdSelector,
  ...shapeMeshProps
}: GeneratedPlanarModelMeshProps): ReactElement {
  const isTapelineActive = useTapelineStore(state => state.isActive)
  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)

  const storey = useGeneratedModelStore(
    useCallback(
      // every item that is not assigned to a storey belongs to the roof
      state => state.currentModelStoreyByGuid[data.guid] || roofStoreyKey,
      [data.guid],
    ),
  )

  const isHiddenByStorey = useGeneratedModelStore(
    useCallback(
      state => availableStoreys.size !== 0 && !!storey && !state.visibleStoreys.has(storey),
      [storey, availableStoreys],
    ),
  )

  const isHiddenByIfcStore = useIfcElementsStore(
    useCallback(state => hiddenIdSelector(state, data.guid), [data.guid, hiddenIdSelector]),
  )

  const isHiddenByElementsStore = useHiddenElementsStore(
    useCallback(
      state =>
        state.hiddenElementIds.has(data.guid) ||
        state.hiddenElementIdsWithoutUserInteraction.has(data.guid),
      [data.guid],
    ),
  )

  const isSelected = useGeneratedModelStore(
    useCallback(state => state.selectedIds.has(data.guid), [data]),
  )

  return (
    <ShapeMesh
      data={data}
      cursor="pointer"
      noPointerInteractions={isTapelineActive}
      visible={!isHiddenByIfcStore && !isHiddenByElementsStore && !isHiddenByStorey}
      outlines
      {...shapeMeshProps}
      shapeColor={isSelected ? sceneColors.selection : shapeMeshProps.shapeColor}
    />
  )
}
