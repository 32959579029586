import { isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Link } from 'react-router-dom'

import ContentCopy from '@mui/icons-material/ContentCopy'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import Send from '@mui/icons-material/Send'
import { Stack, Typography, Button, IconButton, CircularProgress } from '@mui/material'
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import AlertDialog from 'src/components/generic/feedback/AlertDialog'
import { config } from 'src/config/config'
import { Project } from 'src/types'
import { deleteRequest, getRequest, postRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../IfcImporter/controllers/GltfModelController/gltfModelStore'
import { useOpeningsStore } from '../IfcImporter/controllers/OpeningsControllers/openingsStore'
import { useAssignmentsStore } from '../IfcImporter/stores/assignmentsStore'
import { useEditModelStore } from '../IfcImporter/stores/editModelStore'
import { useFiltersStore } from '../IfcImporter/stores/filtersStore'
import { useIfcElementsStore } from '../IfcImporter/stores/ifcElementsStore'
import { useIssuesStore } from '../IfcImporter/stores/issuesStore'
import { useParseAndOrientModelStore } from '../IfcImporter/stores/parseAndOrientModelStore'
import { useProjectStore } from '../IfcImporter/stores/projectStore'
import { typeVisibilityStoreInstances } from '../IfcImporter/stores/typeVisibilityStore'
import { ProjectDialog } from './CreationDialog'

function resetStores() {
  ;[
    useCameraStore,
    useTapelineStore,
    useGltfModelStore,
    useAssignmentsStore,
    useTapelineStore,
    useAssignmentsStore,
    useFiltersStore,
    useIfcElementsStore,
    useParseAndOrientModelStore,
    useOpeningsStore,
    useGeneratedModelStore,
    useEditModelStore,
    useIssuesStore,
    useProjectStore,
    ...Object.values(typeVisibilityStoreInstances),
  ].forEach(store => store.getState().clear())
}

export function Main(): ReactElement {
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean
    mode: 'create' | 'edit' | 'transfer'
    project: Project | null
  }>({
    isOpen: false,
    mode: 'create',
    project: null,
  })
  const { enqueueSnackbar } = useSnackbar()

  // we use this effect to reset all data so the user gets a clean
  // scene/interface when he switches to / creates a new projects
  useEffect(() => resetStores, [])

  const projectsQuery = useQuery(
    'getProjects',
    async () => {
      const { data } = await getRequest<Project[]>({ url: config.apiRoutes.getProjects })
      return data
    },
    {
      onError: () => {
        enqueueSnackbar('Fehler beim Laden der Projekte', { variant: 'error' })
        enqueueSnackbar('Fehler beim Laden der Projekte', { variant: 'error' })
      },
    },
  )

  const remainingProjectsQuery = useQuery('getRemainingProjects', async () => {
    const { data } = await getRequest<{ remaining_projects: number }>({
      url: config.apiRoutes.getRemainingProjects,
    })
    return data.remaining_projects
  })

  const deleteProjectMutation = useMutation(
    async (projectId: string) => {
      await deleteRequest({ url: config.apiRoutes.deleteProject(projectId) })
      await projectsQuery.refetch()
    },
    {
      onError: () => {
        enqueueSnackbar('Fehler beim Löschen des Projekts', { variant: 'error' })
      },
    },
  )

  const copyProjectMutation = useMutation(
    async (projectId: string) => {
      await postRequest({ url: config.apiRoutes.copyProject(projectId) })
      await projectsQuery.refetch()
    },
    {
      onError: () => {
        enqueueSnackbar('Fehler beim Kopieren des Projekts', { variant: 'error' })
      },
    },
  )

  const projects = projectsQuery.isLoading ? [] : projectsQuery.data ? projectsQuery.data : []

  // Helper functions to control dialog
  const openCreateDialog = () => {
    setDialogState({
      isOpen: true,
      mode: 'create',
      project: null,
    })
  }

  const openEditDialog = (project: Project) => {
    setDialogState({
      isOpen: true,
      mode: 'edit',
      project,
    })
  }

  const openTransferDialog = (project: Project) => {
    setDialogState({
      isOpen: true,
      mode: 'transfer',
      project,
    })
  }

  const closeDialog = () => {
    setDialogState(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Projekt',
      flex: 1,
      renderCell: (params: GridRenderCellParams<Project>) => (
        <Link
          to={`/ifc-importer/${params.row.guid}/`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {params.value}
        </Link>
      ),
      sortable: true,
    },
    {
      field: 'created_timestamp',
      headerName: 'Erstellungsdatum',
      valueFormatter: (value: string) => new Date(value).toLocaleString('de-DE'),
      sortable: true,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      renderCell: (params: GridRenderCellParams<Project>) => (
        <Stack direction="row">
          <IconButton size="small" onClick={() => openEditDialog(params.row)}>
            <Edit />
          </IconButton>
          <AlertDialog
            title="Projekt löschen"
            text={`Möchtest du "${params.row.name}" wirklich löschen?`}
            confirmLabel={'Löschen'}
            cancelLabel={'Abbrechen'}
            onConfirm={async () => deleteProjectMutation.mutate(params.row.guid)}
          >
            <IconButton size="small" disabled={deleteProjectMutation.isLoading}>
              <Delete />
            </IconButton>
          </AlertDialog>
          <IconButton
            size="small"
            disabled={copyProjectMutation.isLoading}
            onClick={() => copyProjectMutation.mutate(params.row.guid)}
          >
            <ContentCopy />
          </IconButton>
          <IconButton size="small" onClick={() => openTransferDialog(params.row)}>
            <Send />
          </IconButton>
        </Stack>
      ),
      sortable: false,
      flex: 1,
    },
  ]

  return (
    <>
      <Stack spacing={2} alignItems="center" mt={4}>
        <Typography variant="h2" gutterBottom>
          Modugen IFC Importer
        </Typography>

        <Button
          variant="contained"
          onClick={openCreateDialog}
          disabled={deleteProjectMutation.isLoading}
        >
          {'Neues Projekt erstellen'}
          {!isUndefined(remainingProjectsQuery.data) && ` (${remainingProjectsQuery.data})`}
        </Button>

        {projectsQuery.isLoading ? (
          <Stack>
            <CircularProgress size={24} sx={{ mt: 2 }} />
          </Stack>
        ) : (
          <div style={{ height: 400, width: '100%', maxWidth: 800 }}>
            <DataGridPremium
              rows={projects}
              columns={columns}
              getRowId={(row: Project) => row.guid}
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                sorting: {
                  sortModel: [{ field: 'created_timestamp', sort: 'desc' }],
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              disableRowSelectionOnClick
              density="compact"
              autoHeight
            />
          </div>
        )}
      </Stack>

      {/* Single ProjectDialog instance with dynamic props */}
      <ProjectDialog
        isOpen={dialogState.isOpen}
        onClose={closeDialog}
        mode={dialogState.mode}
        project={dialogState.project}
      />
    </>
  )
}
