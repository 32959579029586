import produce from 'immer'
import { isEqual, isUndefined } from 'lodash-es'

import { ReactElement, useCallback } from 'react'

import { FilterAltOutlined } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'

import StoreySelectBase from 'src/components/shared/generic/StoreySelectBase'
import SwitchLabeled from 'src/components/shared/generic/SwitchLabeled'
import ToggleButtonDropDown from 'src/components/shared/generic/ToggleButtonDropDown'
import useStoreHotkey from 'src/hooks/useStoreHotkey'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'

import useStoreySelection from '../../hooks/useStoreySelection'

export function FloorplanControls(): ReactElement {
  const activeStorey = useEditModelStore(state => state.activeStorey)
  const setActiveStorey = useEditModelStore(state => state.setActiveStorey)

  const visibleStoreys = useEditModelStore(state => state.visibleStoreys)
  const setVisibleStoreys = useEditModelStore(state => state.setVisibleStoreys)

  const showSlabBoundaries = useEditModelStore(state => state.showSlabBoundaries)
  const setShowSlabBoundaries = useEditModelStore(state => state.setShowSlabBoundaries)

  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)

  const chooseStorey = useStoreySelection()

  const storeys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  const toggleStoreyVisibility = useCallback(
    (storey: string) => {
      const newVisibleStoreys = produce(draft => {
        if (draft.has(storey)) draft.delete(storey)
        else draft.add(storey)

        return draft
      }, visibleStoreys)()

      setVisibleStoreys(newVisibleStoreys)
    },
    [setVisibleStoreys, visibleStoreys],
  )

  storeys.map(storey =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStoreHotkey({
      keys: `CTRL+${storey}`,
      callback: () => {
        if (availableStoreys.has(storey.toString())) {
          chooseStorey(storey.toString())
        }
      },
      description: `In Stockwerk ${storey} springen`,
      options: {
        enabled: !isUndefined(activeStorey),
      },
      deps: [chooseStorey, availableStoreys],
    }),
  )

  storeys.map(storey =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStoreHotkey({
      keys: storey.toString(),
      callback: () => {
        if (availableStoreys.has(storey.toString())) {
          toggleStoreyVisibility(storey.toString())
        }
      },
      description: `Stockwerk ${storey} anzeigen`,
      options: {
        enabled: !isUndefined(activeStorey),
      },
      deps: [toggleStoreyVisibility, availableStoreys],
    }),
  )

  return (
    <ToggleButtonDropDown Icon={FilterAltOutlined}>
      <Stack direction="column" spacing={1}>
        <Box minWidth={150}>
          <StoreySelectBase
            visibleStoreys={visibleStoreys}
            availableStoreys={availableStoreys}
            clickMain={storey => storey !== 'Dach' && setActiveStorey(storey)}
            selectedStorey={activeStorey}
            toggleAll={() => {
              if (isEqual(availableStoreys, visibleStoreys)) {
                setVisibleStoreys(new Set())
              } else {
                setVisibleStoreys(availableStoreys)
              }
            }}
            toggleSingle={toggleStoreyVisibility}
          />
        </Box>

        <Stack direction="column">
          <SwitchLabeled
            checked={showSlabBoundaries}
            onChange={event => setShowSlabBoundaries(event.target.checked)}
            label="Deckengrenzen"
          />
        </Stack>
      </Stack>
    </ToggleButtonDropDown>
  )
}
