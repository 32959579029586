import { Vector3 } from 'three'

import { toImmutable } from '@modugen/scene/lib/utils'

import { PlanarWall } from '../types'

// project walls on plane defined by x & y coordinates and remove all
// duplicate vertices caused by the projection
export function projectPlanarWall(wall: PlanarWall, z = 0) {
  return {
    guid: wall.guid,
    placement: wall.placement,
    // simply the base axis points. much easier
    points: [
      toImmutable(new Vector3(wall.shape.points[0].x, wall.shape.points[0].y, z)),
      toImmutable(new Vector3(wall.shape.points[1].x, wall.shape.points[1].y, z)),
    ],
  }
}
