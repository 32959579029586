import React, { MouseEvent } from 'react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { IconButton, List, ListItemButton, ListItemText, Popover, Theme } from '@mui/material'

import { config } from 'src/config/config'

const HelpMenuListItem = ({ onClick, title }: { onClick: () => void; title: string }) => (
  <ListItemButton sx={{ p: 0.25, paddingLeft: 1 }} onClick={onClick}>
    <ListItemText primary={title} />
  </ListItemButton>
)

const HelpMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  return (
    <>
      <IconButton
        // @ts-expect-error
        onClick={(event: MouseEvent) => setAnchorEl(event.currentTarget)}
      >
        <HelpOutlineIcon sx={{ color: (theme: Theme) => theme.palette.common.white }} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List sx={{ minWidth: (theme: Theme) => theme.spacing(17) }} component="div">
          <HelpMenuListItem title="Hilfe" onClick={() => window.open(config.helpURL, '_blank')} />
        </List>
      </Popover>
    </>
  )
}

export default HelpMenu
