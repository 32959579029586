import React, { useState, MouseEvent } from 'react'

import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'

import { useGeneratedModelStore } from '../../../controllers/GeneratedModelController/generatedModelStore'

export function CurrentModelStoreySelect() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)
  const visibleStoreys = useGeneratedModelStore(state => state.visibleStoreys)

  const toggleAllStoreysVisibility = useGeneratedModelStore(
    state => state.toggleAllStoreysVisibility,
  )

  const toggleSingleStoreyVisibility = useGeneratedModelStore(
    state => state.toggleSingleStoreyVisibility,
  )

  return (
    <FormControl fullWidth sx={{ maxWidth: '100%' }}>
      <InputLabel margin="dense">Stockwerke</InputLabel>

      <Select
        // controlled behavior as we open/close the select using onMouseEnter
        // + onMouseLeave instead of onClickAway (= default configuration)
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        multiple
        value={visibleStoreys.size ? [...visibleStoreys] : ['Keine']}
        input={<OutlinedInput label="Stockwerke" />}
        // the component would directly display the menu items otherwise
        renderValue={selected => selected.sort().join(', ')}
        MenuProps={{
          MenuListProps: {
            onMouseLeave: () => setIsOpen(false),
          },

          // making the menu a direct child if the select is required to enable
          // correct mouseenter / leave behavior, disablePortal: true / false is
          // possible as the menu inherits from Popper
          disablePortal: true,
        }}
        size="small"
        fullWidth
        sx={{ maxWidth: '100%' }}
      >
        <MenuItem
          disableGutters
          onClick={(event: MouseEvent<HTMLLIElement>) => {
            // stopPropagation required to prevent closing the drop-down
            event.stopPropagation()
            toggleAllStoreysVisibility()
          }}
        >
          <Checkbox checked={visibleStoreys.size === availableStoreys.size} />
          <ListItemText primary="Alle" />
        </MenuItem>

        {[...availableStoreys]
          .sort()
          .reverse()
          .map(storey => (
            <MenuItem
              key={storey}
              disableGutters
              onClick={(event: MouseEvent) => {
                // stopPropagation required to prevent closing the drop-down
                event.stopPropagation()
                toggleSingleStoreyVisibility(storey)
              }}
            >
              <Checkbox checked={visibleStoreys.has(storey)} />
              <ListItemText primary={storey} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
