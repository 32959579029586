import { filter, find } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { Button, Stack, Typography } from '@mui/material'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Toolbox } from 'src/components/generic/Toolbox'
import { ErrorField, Form } from 'src/components/generic/forms'
import { config } from 'src/config/config'
import { usePlanarWallsInStorey } from 'src/pages/IfcImporter/hooks/usePlanarWallsInStorey'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { projectPlanarWall } from 'src/pages/IfcImporter/utils/projectPlanarWall'
import { SimpleLine } from 'src/types'
import { postRequest } from 'src/utils/requests'

import FormFields from './FormFields'
import { MoveWallSchema, schema } from './schema'

export function MoveWallOrthogonalSubTab(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch'])

  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const selectedWall = useEditModelStore(state => state.selectedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)
  const editedWall = useEditModelStore(state => state.editedWall)

  const moveWallMutation = useMutation(
    async () => {
      const response = await postRequest<PlanarModel>({
        url: config.apiRoutes.postTranslateWall(projectId),
        data: {
          element_guid: selectedWall,
          new_start: {
            x: editedWall?.start.x,
            y: editedWall?.start.y,
            the_norm: 0,
            vector_type: 'floatVec2D',
          },
          new_end: {
            x: editedWall?.end.x,
            y: editedWall?.end.y,
            the_norm: 0,
            vector_type: 'floatVec2D',
          },
        },
      })

      return response.data
    },
    {
      onSuccess: currenModelResponse => {
        setSelectedWall(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.changeWall'), { variant: 'error' })
      },
    },
  )

  const planarWallsInStorey = usePlanarWallsInStorey()

  const wall = useMemo(
    () => find(planarWallsInStorey, { guid: selectedWall }),
    [selectedWall, planarWallsInStorey],
  )

  const planarWallsWithoutCurrentWallAndInStorey = useMemo(
    () =>
      filter(
        planarWallsInStorey,
        planarWall => planarWall.guid !== wall?.guid && planarWall.storey === wall?.storey,
      ),
    [planarWallsInStorey, wall],
  )

  const planarWalls = useMemo<SimpleLine[]>(
    () =>
      planarWallsWithoutCurrentWallAndInStorey.map(wall => {
        const points = projectPlanarWall(wall).points
        const start = points[0] as ImmutableVector3
        const end = points[1] as ImmutableVector3

        return {
          start: [start.x, start.y, 0],
          end: [end?.x, end.y, 0],
        }
      }) || [],
    [planarWallsWithoutCurrentWallAndInStorey],
  )

  const defaultValues = useMemo<MoveWallSchema | undefined>(() => {
    if (!selectedWall || !editedWall) return

    return {
      guid: selectedWall,
      startX: editedWall.start.x,
      startY: editedWall.start.y,
      endX: editedWall.end.x,
      endY: editedWall.end.y,
    }
  }, [selectedWall, editedWall])

  return editedWall && selectedWall ? (
    <Form
      id="change-wall-length"
      onSubmit={moveWallMutation.mutate}
      validationSchema={schema}
      validationContext={{
        planarWalls,
      }}
      defaultValues={defaultValues}
      enableReinitialize
    >
      <Toolbox pt={3}>
        <FormFields />

        <ErrorField name="isIntersecting" />

        <Stack direction="column" spacing={1} overflow="hidden" mt={1}>
          <Button loading={moveWallMutation.isLoading} variant="contained" type="submit" fullWidth>
            {t('common:actions.save')}
          </Button>
        </Stack>
      </Toolbox>
    </Form>
  ) : (
    <Typography textAlign="center">{t('step5Arch:editModelTab.clickWall')}</Typography>
  )
}
