import React, { ReactElement } from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom'

import { Stack } from '@mui/material'

import { MuiBox } from 'src/components/generic/MuiBox'

import { ConditionalSubRoute } from './components/ConditionalSubRoute'
import { IssuesModal } from './components/IssuesModal'
import { StepsNavigation } from './components/StepsNavigation'
import { RoutedSceneController } from './controllers/RoutedSceneController'
import { useProjectState } from './hooks/useProjectState'
import subRouterConfig from './subRouterConfig'
import { getRedirectForProjectState } from './utils/getRedirectForProjectState'
import { Step0Hydrate } from './views/Step0-Hydrate'

export function IfcImporter(): ReactElement {
  const { path } = useRouteMatch()
  const { projectId } = useParams<{ projectId: string }>()

  const projectState = useProjectState()
  const currentFallbackRoute = getRedirectForProjectState(projectState, projectId)

  return (
    <MuiBox display="flex" height={theme => `calc(100vh - ${theme.spacing(8)})`}>
      <MuiBox flexShrink={0} width={theme => theme.spacing(46)}>
        <Switch>
          {subRouterConfig.map(route => (
            <ConditionalSubRoute
              key={route.path}
              path={`${path}/${route.path}`}
              component={route.SidebarLeft}
              // isEnabled and fallbackRoute are just required for one
              // ConditionalSubRoute component per path (as the resulting
              // redirect affects all other components as well)
              isEnabled={route.isEnabled(projectState)}
              fallbackRoute={currentFallbackRoute}
            />
          ))}
        </Switch>
      </MuiBox>

      <Stack
        flexGrow={1}
        alignItems="center"
        borderRight={1}
        borderLeft={1}
        borderColor="grey.200"
        bgcolor="grey.50"
      >
        <StepsNavigation mt={2} flexShrink={0} />
        <RoutedSceneController />
      </Stack>

      <MuiBox flexShrink={0} width={theme => theme.spacing(46)}>
        <Switch>
          {subRouterConfig.map(route => (
            <ConditionalSubRoute
              key={route.path}
              path={`${path}/${route.path}`}
              component={route.SidebarRight}
            />
          ))}
        </Switch>
      </MuiBox>

      <Switch>
        <Route path={path} exact>
          <Step0Hydrate />
        </Route>
      </Switch>

      <IssuesModal />
    </MuiBox>
  )
}
