import { Mesh, Group, Box3, Vector3 } from 'three'

import React, { ReactElement, useLayoutEffect, useMemo, useRef } from 'react'

import CancelPresentationOutlined from '@mui/icons-material/CancelPresentationOutlined'
import Delete from '@mui/icons-material/Delete'
import PanTool from '@mui/icons-material/PanTool'

import { Html } from '@react-three/drei'

import sceneColors from 'src/styles/sceneColors'

import { GltfOpening } from '../../types'

export default function Opening(props: {
  opening: GltfOpening
  state: 'removed' | 'selected' | 'hovered-issue-error' | 'hovered-issue-warning'
}): ReactElement {
  const openingMeshRef = useRef<Mesh>(new Mesh())
  const openingIconRef = useRef<Group | null>(null)

  useLayoutEffect(() => {
    if (openingIconRef.current === null) return

    // render icon in center of the (not visible) opening mesh
    const openingCenter = new Box3().setFromObject(openingMeshRef.current).getCenter(new Vector3())

    openingIconRef.current.position.set(openingCenter.x, openingCenter.y, openingCenter.z)
  }, [])

  const position = useMemo(() => new Float32Array(props.opening.vertices), [props.opening.vertices])
  const index = useMemo(() => new Uint16Array(props.opening.faces), [props.opening.faces])

  return (
    <group>
      <mesh name={props.opening.element_guid} ref={openingMeshRef}>
        {/* we do not actually want to display the opening mesh, hence we set it
            to invisible */}
        <meshBasicMaterial color={0xffff00} visible={false} />
        <bufferGeometry>
          <bufferAttribute
            count={props.opening.vertices.length / 3}
            attach={'attributes-position'}
            array={position}
            itemSize={3}
          />
          <bufferAttribute
            count={props.opening.faces.length}
            attach="index"
            array={index}
            itemSize={1}
          />
        </bufferGeometry>
      </mesh>

      <group ref={openingIconRef}>
        <Html
          style={{
            pointerEvents: 'none',
          }}
          center
        >
          {/* unfortunately switch does not work in tsx, because of that we use this construct */}
          {props.state === 'hovered-issue-error' && (
            <CancelPresentationOutlined
              sx={{
                color: sceneColors.issues.error,
              }}
            />
          )}

          {props.state === 'hovered-issue-warning' && (
            <CancelPresentationOutlined
              sx={{
                color: sceneColors.issues.warning,
              }}
            />
          )}

          {props.state === 'removed' && (
            <Delete
              sx={{
                color: sceneColors.openings.removed,
              }}
            />
          )}

          {props.state === 'selected' && (
            <PanTool
              sx={{
                color: sceneColors.openings.selected,
              }}
            />
          )}
        </Html>
      </group>
    </group>
  )
}
