import React, { ReactElement } from 'react'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { Theme, Typography } from '@mui/material'

import { MuiBox } from '../../MuiBox'

interface Props {
  severity?: 'error' | 'warning'
  message: string
}

const ErrorFieldBase = ({ severity = 'error', message }: Props): ReactElement => {
  const isError = severity === 'error'

  return (
    <MuiBox
      sx={({ spacing, palette }) => ({
        display: 'flex',
        padding: spacing(0.5),
        background: '#fff',
        color: palette[isError ? 'error' : 'warning'].main,
        border: '1px solid',
        borderColor: palette[isError ? 'error' : 'warning'].main,
        borderRadius: '4px',
      })}
    >
      <ErrorOutline fontSize="small" sx={{ marginRight: ({ spacing }: Theme) => spacing(0.5) }} />
      <Typography sx={{ fontSize: 12 }}>{message}</Typography>
    </MuiBox>
  )
}

export default ErrorFieldBase
