import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'

import ArrowForward from '@mui/icons-material/ArrowForward'
import UploadFile from '@mui/icons-material/UploadFile'
import { Stack, Theme, Typography, Button } from '@mui/material'

import { AbortionPrompt, useUnblockHistory } from 'src/components/generic/AbortionPrompt'
import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { MuiBox } from 'src/components/generic/MuiBox'
import { isLatin1 } from 'src/utils/misc'

import { useGltfModelStore } from '../../controllers/GltfModelController/gltfModelStore'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { useProjectState } from '../../hooks/useProjectState'
import { getAndParseGltfModel } from '../../queries/getAndParseGltfModel'
import { getElements, setElements } from '../../queries/getAndSetElements'
import { uploadAndConvertIfcFile } from '../../queries/uploadAndConvertIfcFile'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'

export function SidebarLeft(): ReactElement {
  const { t } = useTranslation('step1Upload')
  const history = useHistory()
  const unblockHistory = useUnblockHistory()
  const controllerRef = useRef(new AbortController())
  const { projectId } = useParams<{ projectId: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const setGltfModel = useGltfModelStore(state => state.setGltfModel)

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.ifc',
    maxFiles: 1,
    validator: file => {
      if (!isLatin1(file.name)) {
        enqueueSnackbar(t('errors.forbiddenCharactersInFilename'), {
          variant: 'error',
          persist: false,
        })
        return {
          code: 'non-latin-1-characters',
          message: t('errors.forbiddenCharactersInFilename'),
        }
      }

      return null
    },
  })

  const uploadMutation = useMutation(
    async () => {
      await uploadAndConvertIfcFile({
        projectId,
        ifcFile: acceptedFiles[0],
        signal: controllerRef.current.signal,
      })

      const elements = await getElements(projectId, controllerRef.current.signal)
      const gltfModel = await getAndParseGltfModel(projectId, controllerRef.current.signal)

      return { elements, gltfModel }
    },
    {
      onSuccess: ({ elements, gltfModel }) => {
        setElements(elements)
        setGltfModel(gltfModel)

        unblockHistory()
        history.push(`/ifc-importer/${projectId}/step-2-assignment`)
      },
      onError: () => {
        enqueueSnackbar(t('errors.uploadAndConvertIfc'), { variant: 'error', persist: true })
      },
    },
  )

  return (
    <CustomSidebar title="Modell Upload">
      <Stack spacing={2}>
        <Typography>{t('actions.uploadIfc')}:</Typography>

        <MuiBox
          sx={{
            padding: 3,
            border: theme => `1px dashed ${theme.palette.grey[isDragActive ? 800 : 400]}`,
            borderRadius: 1,
            bgcolor: 'grey.100',
            cursor: 'pointer',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Stack spacing={2} alignItems="center">
            <UploadFile
              sx={{
                marginTop: 0,
                width: (theme: Theme) => theme.spacing(8),
                height: (theme: Theme) => theme.spacing(8),
                color: 'primary.main',
              }}
            />

            <Typography textAlign="center">{t('actions.dropIfcFile')}</Typography>

            {acceptedFiles[0] && (
              <Typography fontWeight="bold" fontStyle="italic">
                {acceptedFiles[0].name}
              </Typography>
            )}
          </Stack>
        </MuiBox>

        <Button
          onClick={() => uploadMutation.mutate()}
          disabled={!acceptedFiles[0]}
          loading={uploadMutation.isLoading}
          startIcon={<UploadFile />}
          variant="contained"
        >
          {t('actions.uploadIfcFile')}
        </Button>
      </Stack>

      <AbortionPrompt
        text="Hochladen wird abgebrochen"
        blocked={uploadMutation.isLoading}
        onLeave={() => controllerRef.current.abort()}
      />
    </CustomSidebar>
  )
}

export function Scene(): ReactElement | null {
  const setHiddenIds = useIfcElementsStore(state => state.setHiddenIdsGltfModel)

  // nothing should be hidden in the first view
  useEffect(() => setHiddenIds(new Set()), [])
  useShowOpenings(true)

  return null
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step1Upload'])
  const history = useHistory()
  const { projectId } = useParams<{ projectId: string }>()
  const projectState = useProjectState()

  const setIsInitialUpload = useGltfModelStore(state => state.setIsInitialUpload)

  return (
    <CustomSidebar
      title="Modell Upload bestätigen"
      bottom={
        <Button
          onClick={() => {
            setIsInitialUpload(false)
            history.push(`/ifc-importer/${projectId}/step-2-assignment`)
          }}
          disabled={!projectState.hasModel}
          startIcon={<ArrowForward />}
          variant="contained"
          fullWidth
        >
          {t('common:actions.confirmAndContinue')}
        </Button>
      }
    >
      {projectState.hasModel && (
        <>
          <Typography gutterBottom>{t('step1Upload:hints.filterModel')}</Typography>

          <Typography>{t('step1Upload:hints.changeModel')}</Typography>
        </>
      )}
    </CustomSidebar>
  )
}
