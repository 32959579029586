import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { Button, Stack, Typography } from '@mui/material'

import { Toolbox } from 'src/components/generic/Toolbox'
import { Form } from 'src/components/generic/forms'
import Select from 'src/components/generic/forms/Select'
import { config } from 'src/config/config'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { postRequest } from 'src/utils/requests'

import { ChangeWallSchema } from './schema'

export function ChangeWallPlacement() {
  const { t } = useTranslation(['common', 'step5Arch'])

  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const selectedWall = useEditModelStore(state => state.selectedWall)

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid: selectedWall }),
    [selectedWall, currentModelPlanar],
  )

  const defaultValues = useMemo<ChangeWallSchema | undefined>(
    () =>
      wall
        ? {
            element_guid: wall.guid,
            placement: wall.placement,
          }
        : undefined,
    [wall],
  )

  const changePlacement = useMutation(
    async (data: ChangeWallSchema) =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postUpdateWallPlacement(projectId),
          data: [data],
        })
      ).data,
    {
      onSuccess: newModel => {
        enqueueSnackbar(t('step5Arch:successMessages.changePlacement'), { variant: 'success' })
        setCurrentModel(newModel)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.changePlacement'), { variant: 'error' })
      },
    },
  )

  return defaultValues ? (
    <Form onSubmit={changePlacement.mutate} defaultValues={defaultValues} enableReinitialize>
      <Toolbox pt={3}>
        <Stack direction="row" spacing={2}>
          <Select
            name="placement"
            inputLabel={t('step5Arch:editModelTab.wallType')}
            options={[
              {
                value: 'Internal',
                label: t('step5Arch:editModelTab.innerWall'),
              },
              {
                value: 'External',
                label: t('step5Arch:editModelTab.outerWall'),
              },
            ]}
          />
          <Button loading={changePlacement.isLoading} variant="contained" type="submit">
            {t('common:actions.save')}
          </Button>
        </Stack>
      </Toolbox>
    </Form>
  ) : (
    <Typography textAlign="center">{t('step5Arch:editModelTab.clickWall')}</Typography>
  )
}
