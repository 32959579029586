import { useCallback } from 'react'

import { useRoofStore } from '../store'

const useSelectRow = () => {
  const setCellSelection = useRoofStore(state => state.setCellSelection)

  const selectRow = useCallback(
    (pId: string) => {
      setCellSelection({ [pId]: { x: true, y: true, z: true, actions: true, __reorder__: true } })
    },
    [setCellSelection],
  )

  return selectRow
}

export default useSelectRow
