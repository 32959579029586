import { useSnackbar } from 'notistack'

import React from 'react'
import { useTranslation } from 'react-i18next'

import Cancel from '@mui/icons-material/Cancel'
import Visibility from '@mui/icons-material/Visibility'
import { Tooltip } from '@mui/material'

import { ThreeEvent } from '@react-three/fiber'

import {
  HeaderToggleButtonGroup,
  HeaderToggleButton,
} from 'src/components/generic/HeaderToggleButtonGroup'
import HotKeyHeaderToggleButton from 'src/components/generic/HotKeyHeaderToggleButton'

import { useOnClickCurrentModel } from '../../controllers/GeneratedModelController/useOnClickGeneratedModel'
import { useOnClickGltfModel } from '../../controllers/GltfModelController/useOnClickGltfModel'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useEditModelStore } from '../../stores/editModelStore'
import { useHiddenElementsStore } from '../../stores/hiddenElementsIdsStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'

export function ElementVisibilityControls() {
  const { t } = useTranslation('ifcImporter')
  const { enqueueSnackbar } = useSnackbar()

  const {
    isElementVisibilityMode,
    addHiddenElementIds,
    setisElementVisibilityMode,
    deselectAllHiddenElementIds,
  } = useHiddenElementsStore()

  const allIfcIds = useIfcElementsStore(state => state.allIfcIds)
  const selectedStandAloneIds = useIfcElementsStore(state => state.selectedStandAloneIds)
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const groupIdByChildId = useDerivedIfcDataStore(state => state.groupIdByChildId)
  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  function onClickElement(event: ThreeEvent<MouseEvent>): void {
    if (!allIfcIds.size || !isElementVisibilityMode) return

    const ifcElementId = event.object.name

    if (selectedStandAloneIds.has(ifcElementId)) {
      enqueueSnackbar(t('issuesModal.selectElementWarning'), {
        variant: 'warning',
      })

      return
    }

    if (!ifcElementId) return

    // element is a group
    if (trueIfcGroups[ifcElementId]) {
      addHiddenElementIds([ifcElementId])
    }
    // element is a group child
    else if (groupIdByChildId[ifcElementId]) {
      // gets the group id
      const groupId = groupIdByChildId[ifcElementId]
      // get all other ifcElementIds with the same parent groupId
      const groupIfcElementIds = trueIfcGroups[groupId]?.map(id => id)
      addHiddenElementIds(groupIfcElementIds)
    }
    // element is stand alone
    else {
      addHiddenElementIds([ifcElementId])
    }
  }

  // listens to scene object clicks when in isElementVisibilityMode
  const modelClickDependencies = [
    isElementVisibilityMode,
    allIfcIds,
    trueIfcGroups,
    selectedStandAloneIds,
  ]

  useOnClickGltfModel(onClickElement, modelClickDependencies)
  useOnClickCurrentModel(onClickElement, modelClickDependencies)

  return (
    <HeaderToggleButtonGroup size="small">
      <Tooltip title={t('elementVisibility.hideElements')}>
        <HotKeyHeaderToggleButton
          value="active"
          size="small"
          onClick={() => setisElementVisibilityMode(!isElementVisibilityMode)}
          selected={isElementVisibilityMode}
          disabled={!!activeDrawingStorey}
          hotkeys="h"
          onHotkey={() => setisElementVisibilityMode(!isElementVisibilityMode)}
          hotkeyDescription={t('elementVisibility.toggleVisibilityTool')}
          hotkeysDeps={[isElementVisibilityMode]}
        >
          <Visibility fontSize="small" />
        </HotKeyHeaderToggleButton>
      </Tooltip>

      <Tooltip title={t('elementVisibility.unhideElements')}>
        <HeaderToggleButton
          value="visible"
          size="small"
          onClick={() => deselectAllHiddenElementIds()}
          selected={false}
          disabled={!!activeDrawingStorey}
        >
          <Cancel fontSize="small" />
        </HeaderToggleButton>
      </Tooltip>
    </HeaderToggleButtonGroup>
  )
}
