import { reject, toNumber } from 'lodash-es'

import { ReactElement, useCallback, useState } from 'react'

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'

import sceneColors from 'src/styles/sceneColors'

interface Props {
  visibleStoreys: Set<string>
  availableStoreys: Set<string>
  toggleAll: () => void
  toggleSingle: (storey: string) => void
  clickMain?: (storey: string) => void
  selectedStorey?: string
}

const StoreySelectBase = ({
  visibleStoreys,
  availableStoreys,
  toggleAll,
  toggleSingle,
  clickMain,
  selectedStorey,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const renderValue = useCallback(() => {
    const visibleStoreysFiltered = reject([...visibleStoreys], storey => storey === selectedStorey)

    if (visibleStoreysFiltered.length === 0) return selectedStorey
    else if (visibleStoreysFiltered.length !== 0)
      return `${selectedStorey} - ${[...visibleStoreysFiltered].sort().join(', ')}`
  }, [selectedStorey, visibleStoreys])

  const storeyAsNumber = toNumber(selectedStorey)

  return (
    <FormControl fullWidth sx={{ maxWidth: '100%', marginTop: theme => theme.spacing(1) }}>
      <InputLabel margin="dense">Stockwerke</InputLabel>

      <Select
        // controlled behavior as we open/close the select using onMouseEnter
        // + onMouseLeave instead of onClickAway (= default configuration)
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        multiple
        value={[selectedStorey]}
        input={<OutlinedInput label="Stockwerke" />}
        // the component would directly display the menu items otherwise
        renderValue={renderValue}
        MenuProps={{
          MenuListProps: {
            onMouseLeave: () => setIsOpen(false),
          },

          // making the menu a direct child if the select is required to enable
          // correct mouseenter / leave behavior, disablePortal: true / false is
          // possible as the menu inherits from Popper
          disablePortal: true,
        }}
        size="small"
        fullWidth
        sx={{ maxWidth: '100%' }}
        data-cy={`storey-select`}
      >
        <MenuItem disableGutters onClick={event => event.stopPropagation()} value="Dach">
          <ListItemText primary="Alle" sx={{ paddingLeft: 2 }} />

          <Checkbox
            checked={visibleStoreys.size === availableStoreys.size}
            onClick={event => {
              // stopPropagation required to prevent closing the drop-down
              event.stopPropagation()
              toggleAll()
            }}
          />
        </MenuItem>

        {[...availableStoreys]
          .sort()
          .reverse()
          .map(storey => {
            const isBelow = storeyAsNumber > toNumber(storey)

            return (
              <MenuItem
                key={storey}
                disableGutters
                onClick={event => {
                  // stopPropagation required to prevent closing the drop-down
                  event.stopPropagation()
                  clickMain?.(storey)
                }}
                selected={selectedStorey === storey}
                value={storey}
              >
                <ListItemText primary={storey} sx={{ paddingLeft: 2 }} />
                {storey !== selectedStorey && storey !== 'Dach' && visibleStoreys.has(storey) && (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: isBelow
                        ? sceneColors.elementsBelowActiveStorey
                        : sceneColors.elementsAboveActiveStorey,
                    }}
                  />
                )}
                <Checkbox
                  checked={visibleStoreys.has(storey) || storey === selectedStorey}
                  onClick={event => {
                    event.stopPropagation()
                    toggleSingle(storey)
                  }}
                  data-cy={`storey-select-item-checkbox-${storey}`}
                  disabled={storey === selectedStorey}
                />
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}

export default StoreySelectBase
