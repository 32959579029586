import { find, isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { Button } from '@mui/material'

import { config } from 'src/config/config'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { useHiddenElementsStore } from 'src/pages/IfcImporter/stores/hiddenElementsIdsStore'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { patchRequest } from 'src/utils/requests'

import RoofEditingForm from '../../../../../../components/shared/interaction/RoofEditing/expose/components/RoofEditingForm'

interface Props {
  slabGuid: string
}

const RoofEditing = ({ slabGuid }: Props): ReactElement => {
  const { projectId } = useParams<{ projectId: string }>()

  const roofSlabs = useGeneratedModelStore(state => state.currentModelPlanar?.roof_slabs)
  const slab = find(roofSlabs, slab => slab.guid === slabGuid)

  const setCurrentModelPlanar = useGeneratedModelStore(state => state.setCurrentModelPlanar)

  const setSelectedRoofSlab = useEditModelStore(state => state.setSelectedRoofSlab)

  const setHiddenElementIdsWithoutUserInteraction = useHiddenElementsStore(
    state => state.setHiddenElementIdsWithoutUserInteraction,
  )

  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation(['common', 'step5Arch'])

  const { mutateAsync } = useMutation(
    async (points: Point[]) =>
      (
        await patchRequest<PlanarModel>({
          url: config.apiRoutes.postUpdateRoofSlab(projectId, slabGuid),
          data: { points },
        })
      ).data,
    {
      onSuccess: currenModelResponse => {
        setCurrentModelPlanar(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.changeRoofSlab'), { variant: 'error' })
      },
    },
  )

  useEffect(() => {
    if (!isUndefined(slab)) {
      setHiddenElementIdsWithoutUserInteraction([slab.guid])

      return () => setHiddenElementIdsWithoutUserInteraction([])
    }
  }, [slab])

  if (!slab) return <></>

  return (
    <RoofEditingForm
      key={slabGuid}
      initialPoints={slab?.shape.points}
      onSave={async points => {
        await mutateAsync(points)
      }}
      actions={
        <Button onClick={() => setSelectedRoofSlab()} variant="outlined">
          {t('common:actions.deselectElements')}
        </Button>
      }
    />
  )
}

export default RoofEditing
