import React, { ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { AppState, Auth0Provider } from '@auth0/auth0-react'

import { config } from 'src/config/config'

// see: https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app

export function AuthenticationProvider({ children }: { children: ReactNode }): ReactElement {
  const history = useHistory()

  const onRedirectCallback = (appState?: AppState) => {
    history.replace(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.auth0Domain || ''}
      clientId={config.auth0ClientID || ''}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  )
}
