import React, { MutableRefObject, ReactElement, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import { R3FDreiHtmlMaxZIndex } from 'src/config/misc'
import { useStateRef } from 'src/utils/useStateRef'

/**
 * Overwrite the blocked history, useful if AbortionPrompt still blocks history
 * but you want to navigate anyway
 * @param path
 * @returns
 */
export function useUnblockHistory(): () => void {
  const history = useHistory()

  return () => {
    const unblock = history.block(() => undefined)
    unblock()
  }
}

export function AbortionPrompt(props: {
  text?: string
  onLeave?: () => void
  blocked?: boolean
  ref?: MutableRefObject<EventTarget>
  allowedPaths?: string[]
}): ReactElement {
  const history = useHistory()
  const modalOpenRef = useStateRef(false)
  const desiredLocationRef = useRef<null | string>(null)
  const unblock = useRef<() => void | undefined>()

  useEffect(() => {
    if (props.blocked) {
      unblock.current = history.block(location => {
        // Allow overwriting of AbortionPrompt block, e.g. if we want to allow
        // going to specific routes
        if (props.allowedPaths?.includes(location.pathname)) return

        // We need to set the history the user wants to use here and block the
        // request in any case. The routing (pushing to history) will be
        // done once the user actually confirms
        desiredLocationRef.current = location.pathname
        if (!modalOpenRef.stateRef.current) modalOpenRef.set(true)
        return false
      })
    } else {
      unblock.current?.()
    }

    return () => {
      unblock.current?.()
    }
  }, [props.blocked, props.allowedPaths])

  return (
    <Dialog open={modalOpenRef.state} sx={{ zIndex: R3FDreiHtmlMaxZIndex + 1000 }}>
      <DialogTitle>Achtung!</DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        {props.text ? (
          <DialogContentText>{props.text}</DialogContentText>
        ) : (
          <DialogContentText>
            Der Vorgang ist noch nicht abgeschlossen. Wenn du die Ansicht jetzt verlässt, wird der
            Vorgang abgebrochen
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => modalOpenRef.set(false)} variant="outlined">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            props.onLeave?.()
            unblock.current?.()
            history.push(desiredLocationRef.current as string)
            modalOpenRef.set(false)
          }}
          variant="outlined"
          color="error"
        >
          Verlassen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
