import React, { ReactElement, MouseEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, useLocation, useRouteMatch, BrowserRouter } from 'react-router-dom'

import { Stack, Typography } from '@mui/material'

import { ModuGenScene, useCameraStore } from '@modugen/scene/lib'

import { MuiBox } from 'src/components/generic/MuiBox'
import { HeaderPortalLeft, HeaderPortalRight } from 'src/components/global/HeaderBar'

import { ConditionalSubRoute } from '../components/ConditionalSubRoute'
import DrawControls from '../components/controls/DrawControls'
import { ElementVisibilityControls } from '../components/controls/ElementVisibilityControls'
import OutlineControlsWidget from '../components/controls/OutlineControls'
import { PanControls } from '../components/controls/PanControls'
import { TapelineControls } from '../components/controls/TapelineControls'
import { useEditModelStore } from '../stores/editModelStore'
import subRouterConfig from '../subRouterConfig'
import { ArchViewTabs } from '../views/step5-Arch'
import { GltfModelController } from './GltfModelController'
import { useGltfModelStore } from './GltfModelController/gltfModelStore'
import { OpeningsController } from './OpeningsControllers/OpeningsController'

// this should, if possible, be the only place where MUI and R3F mix
export function RoutedSceneController(): ReactElement {
  const { t } = useTranslation('ifcImporter')
  const location = useLocation()
  const { path } = useRouteMatch()

  const gltfModel = useGltfModelStore(state => state.gltfModel)

  const editMode = useEditModelStore(state => state.editMode)
  const activeTabIndex = useEditModelStore(state => state.activeTabIndex)
  const activeStorey = useEditModelStore(state => state.activeStorey)

  const cameraRotationTarget = useCameraStore(state => state.rotationTarget)

  const gltfModelVisible = useMemo(() => {
    if (!!activeStorey && !!editMode && editMode !== 'opening') return false
    if (
      !!activeStorey &&
      (activeTabIndex === ArchViewTabs.Placement || activeTabIndex === ArchViewTabs.Alignment)
    )
      return false

    return true
  }, [activeTabIndex, editMode, activeStorey])

  const pointLightPositions: [x: number, y: number, z: number][] = [
    [cameraRotationTarget.x + 30, cameraRotationTarget.y + 25, 0],
    [cameraRotationTarget.x + 25, cameraRotationTarget.y + -30, 1],
    [cameraRotationTarget.x + -30, cameraRotationTarget.y + -25, 0],
    [cameraRotationTarget.x + -25, cameraRotationTarget.y + 30, 1],
  ]

  return !gltfModel ? (
    <Stack flexGrow={1} alignSelf="stretch" justifyContent="center" alignItems="center">
      <Typography variant="h3" gutterBottom>
        {t('routedSceneController.modelNotAvailable')}
      </Typography>
      <Typography>{t('routedSceneController.ifcFileUpload')}</Typography>
    </Stack>
  ) : (
    <>
      <MuiBox
        position="relative"
        flexGrow={1}
        alignSelf="stretch"
        overflow="hidden"
        // prevents selecting HTML elements outside of the scene while clicking
        // or dragging on the scene
        onMouseDown={(event: MouseEvent) => {
          // we need to allow this on HTMLInput elements though hence they might
          // be part of the scene
          if (!(event.target instanceof HTMLInputElement)) {
            event.preventDefault()
          }
        }}
      >
        <ModuGenScene>
          <ambientLight intensity={0.8 * Math.PI} />
          {pointLightPositions.map((pos, key) => (
            <pointLight key={key} position={pos} intensity={0.5 * Math.PI} />
          ))}

          {/* a separate router is required as components inside the 3RF scene
              cannot share context with the rest of the application. syncing
              both worlds is achieved through sharing the location object */}
          <BrowserRouter>
            <Switch location={location}>
              {subRouterConfig.map(route => (
                <ConditionalSubRoute
                  key={route.path}
                  path={`${path}/${route.path}`}
                  component={route.Scene}
                />
              ))}
            </Switch>
          </BrowserRouter>
          <group visible={gltfModelVisible}>
            {/* due to performance reasons it (unfortunately) seems to make sense
              to leave heavy objects permanently inside the scene and control
              their visibility through other means */}
            <GltfModelController displayMode="edit" />
            <OpeningsController />
          </group>
        </ModuGenScene>
      </MuiBox>

      <HeaderPortalLeft>
        <Stack direction="row" spacing={1}>
          <TapelineControls />
          <DrawControls />
          <ElementVisibilityControls />
          <OutlineControlsWidget />
        </Stack>
      </HeaderPortalLeft>

      <HeaderPortalRight>
        <Stack direction="row" spacing={1}>
          <PanControls />
        </Stack>
      </HeaderPortalRight>
    </>
  )
}
