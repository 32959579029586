import { isString, isFunction } from 'lodash-es'

import React, {
  ReactElement,
  useState,
  ReactNode,
  isValidElement,
  cloneElement,
  MouseEvent,
} from 'react'

import { Button, Typography } from '@mui/material'

import DialogBase from '../DialogBase'

interface Props {
  title: string
  text?: string | ReactElement
  confirmLabel?: string
  cancelLabel?: string | null
  onConfirm?: () => Promise<Record<string, unknown> | void> | void
  isOpen?: boolean
  children: ReactElement
}

// Component (mostly) copied from modugen static. Smaller improvements done
const AlertDialog = ({
  title,
  text,
  isOpen = false,
  confirmLabel = 'Bestätigen',
  cancelLabel = 'Abbrechen',
  onConfirm,
  children,
}: Props): ReactElement => {
  const [open, setOpen] = useState<boolean>(isOpen)
  const [loading, setLoading] = useState<boolean>(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const childrenWithProps = React.Children.map<ReactNode, ReactNode>(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        // @ts-ignore
        onClick: (event: MouseEvent) => {
          event.stopPropagation()
          event.preventDefault()
          handleClickOpen()
        },
      })
    }
    return child
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isFunction(onConfirm)) return handleClose()

    const isAsync = onConfirm.constructor.name === 'AsyncFunction'

    if (isAsync) {
      setLoading(true)
      // typescript cannot handle async and non async type
      // @ts-ignore
      onConfirm().finally(() => {
        setLoading(false)
        handleClose()
      })

      return
    }

    onConfirm()
    handleClose()
  }

  return (
    <>
      {childrenWithProps}
      <DialogBase open={open} onClose={handleClose} title={title}>
        {isString(text) ? (
          <Typography>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </Typography>
        ) : (
          text
        )}
        <>
          {cancelLabel && (
            <Button
              onClick={(event: MouseEvent) => {
                event.stopPropagation()
                handleClose()
              }}
            >
              {cancelLabel}
            </Button>
          )}
          <Button
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            autoFocus
          >
            {confirmLabel}
          </Button>
        </>
      </DialogBase>
    </>
  )
}

export default AlertDialog
