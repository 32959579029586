import { MouseEventHandler, ReactElement } from 'react'

import { ChangeHistory, HelpOutline, Restore, RotateLeft, SwapVert } from '@mui/icons-material'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

interface Props {
  onReset: () => void
  onRotate: () => void
  onPressComputeZ: () => void
  onChangeOrder: () => void
  onHelp: MouseEventHandler<HTMLButtonElement>
}

const Toolbar = ({
  onReset,
  onRotate,
  onPressComputeZ,
  onChangeOrder,
  onHelp,
}: Props): ReactElement => {
  return (
    <GridToolbarContainer>
      <Stack direction="row" justifyContent="space-between" flex={1}>
        <Stack direction="row">
          <Tooltip title="Zurücksetzen" placement="top">
            <IconButton onClick={onReset}>
              <Restore fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Punkte gegen Uhrzeigersinn sortieren" placement="top">
            <IconButton onClick={onRotate}>
              <RotateLeft fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Z Angleichen (Alle Punkte auf gleiche Ebene bringen)" placement="top">
            <IconButton onClick={onPressComputeZ}>
              <ChangeHistory fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Punkte rotieren" placement="top">
            <IconButton onClick={onChangeOrder}>
              <SwapVert fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>

        <IconButton onClick={onHelp}>
          <HelpOutline fontSize="small" />
        </IconButton>
      </Stack>
    </GridToolbarContainer>
  )
}

export default Toolbar
