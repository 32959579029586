import { useSnackbar } from 'notistack'

import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { Button, FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import HotKeyButton from 'src/components/generic/HotKeyButton'
import { Toolbox } from 'src/components/generic/Toolbox'
import { Form } from 'src/components/generic/forms'
import { config } from 'src/config/config'
import { roofStoreyKey } from 'src/config/misc'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel, PlanarWall, WallPlacement } from 'src/pages/IfcImporter/types'
import { postRequest } from 'src/utils/requests'

import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import useStoreySelection from './hooks/useStoreySelection'

export function ChangeWallPlacementTab() {
  const [placement, setPlacement] = useState<WallPlacement>('Internal')

  const { projectId } = useParams<{ projectId: string }>()

  const { enqueueSnackbar } = useSnackbar()

  const availableStoreys = useGeneratedModelStore(state => state.availableStoreys)

  const activeStorey = useEditModelStore(state => state.activeStorey)

  const selectedWallsForPlacement = useEditModelStore(state => state.selectedWallsForPlacement)
  const setSelectedWallsForPlacement = useEditModelStore(
    state => state.setSelectedWallsForPlacement,
  )

  const chooseStorey = useStoreySelection()

  useEffect(
    () => () => setSelectedWallsForPlacement([]),
    [activeStorey, setSelectedWallsForPlacement],
  )

  const changePlacement = useMutation(
    async () => {
      const data = selectedWallsForPlacement.map(wall => ({
        element_guid: wall,
        placement: placement,
      }))

      return (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postUpdateWallPlacement(projectId),
          data: data,
        })
      ).data
    },
    {
      onSuccess: setCurrentModel,
      onError: () => {
        enqueueSnackbar('Fehler beim Ändern der Wandplatzierung', { variant: 'error' })
      },
    },
  )

  return (
    <>
      <CustomSidebar>
        <Stack spacing={2} mt={1}>
          <FormControl>
            <TextField
              select
              label="Stockwerk auswählen"
              value={activeStorey || ''}
              onChange={event => chooseStorey((event.target.value as string) || undefined)}
              size="small"
              sx={{ bgcolor: 'grey.50' }}
            >
              <MenuItem value={''}>Bearbeitungsmodus verlassen</MenuItem>

              {[...availableStoreys]
                .reverse()
                .filter(storey => storey !== roofStoreyKey)
                .map(storey => (
                  <MenuItem value={storey} key={storey}>
                    {storey}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>

          {!activeStorey && (
            <Typography textAlign="center">
              Wähle ein Stockwerk um in den Bearbeitungsmodus zu wechseln
            </Typography>
          )}

          {!!activeStorey && (
            <>
              {selectedWallsForPlacement.length ? (
                <>
                  <Toolbox>
                    <HotKeyButton
                      onClick={() => setSelectedWallsForPlacement([])}
                      size="small"
                      variant="outlined"
                      fullWidth
                      hotkeys="q"
                      onHotkey={() => setSelectedWallsForPlacement([])}
                      hotkeyDescription="Ausw. aufheben"
                    >
                      Ausw. aufheben
                    </HotKeyButton>
                  </Toolbox>

                  <Form onSubmit={changePlacement.mutate}>
                    <Toolbox pt={3}>
                      <TextField
                        select
                        value={placement}
                        onChange={e => setPlacement(e.target.value as PlanarWall['placement'])}
                        label="Wandtyp"
                        size="small"
                        sx={{ bgcolor: 'grey.50' }}
                      >
                        <MenuItem value="Internal">Innenwand</MenuItem>
                        <MenuItem value="External">Außenwand</MenuItem>
                      </TextField>
                      <Button
                        loading={changePlacement.isLoading}
                        variant="contained"
                        type="submit"
                        fullWidth
                      >
                        Speichern
                      </Button>
                    </Toolbox>
                  </Form>
                </>
              ) : (
                <Typography textAlign="center">Klicke auf eine bestehende Wand</Typography>
              )}
            </>
          )}
        </Stack>
      </CustomSidebar>
    </>
  )
}
