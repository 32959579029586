import produce from 'immer'
import { reduce, sortBy } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import Delete from '@mui/icons-material/Delete'
import UploadFile from '@mui/icons-material/UploadFile'
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
  Stack,
  Switch,
  Divider,
  Theme,
  Button,
} from '@mui/material'

import { AbortionPrompt } from 'src/components/generic/AbortionPrompt'
import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { MuiBox } from 'src/components/generic/MuiBox'
import { config } from 'src/config/config'
import { isLatin1, isNumber } from 'src/utils/misc'
import { getRequest, postRequest, uploadFile } from 'src/utils/requests'

import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../../controllers/GltfModelController/gltfModelStore'
import { applySchnittbombe, UpdateStoreyHeightsOptions } from '../../queries/applySchnittbombe'
import { getCurrentModel, setCurrentModel } from '../../queries/getAndSetCurrentModel'
import { useIssuesStore } from '../../stores/issuesStore'
import { CoordinateSystem, PlanarModel } from '../../types'
import { useArchViewStore } from './archViewStore'

const RemoveStoreyButton = ({ storey }: { storey: number }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'step5Arch', 'ifcImporter'])
  const { projectId } = useParams<{ projectId: string }>()
  const setCoordinateSystem = useGltfModelStore(state => state.setCoordinateSystem)
  const removeStorey = useMutation(
    async () =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRemoveStorey(projectId, storey),
        })
      ).data,
    {
      onSuccess: async currenModelResponse => {
        setCurrentModel(currenModelResponse)
        const coordinateSystem = (
          await getRequest<CoordinateSystem>({
            url: config.apiRoutes.getCoordinateSystem(projectId),
          })
        ).data
        setCoordinateSystem(coordinateSystem)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.removeStorey'), { variant: 'error' })
      },
    },
  )
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={<Delete />}
      onClick={() => removeStorey.mutate()}
      loading={removeStorey.isLoading}
    />
  )
}

const Rotate90Button = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'step5Arch', 'ifcImporter'])
  const { projectId } = useParams<{ projectId: string }>()
  const setCoordinateSystem = useGltfModelStore(state => state.setCoordinateSystem)
  const rotateModelMutation = useMutation(
    async () =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRotate90(projectId),
        })
      ).data,
    {
      onSuccess: async currenModelResponse => {
        setCurrentModel(currenModelResponse)
        const coordinateSystem = (
          await getRequest<CoordinateSystem>({
            url: config.apiRoutes.getCoordinateSystem(projectId),
          })
        ).data
        setCoordinateSystem(coordinateSystem)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.rotate90'), { variant: 'error' })
      },
    },
  )
  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() => rotateModelMutation.mutate()}
      loading={rotateModelMutation.isLoading}
    >
      Modell +90 drehen
    </Button>
  )
}

const UploadModelForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId } = useParams<{ projectId: string }>()
  const controllerRef = useRef(new AbortController())

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.json',
    maxFiles: 1,
    validator: file => {
      if (!isLatin1(file.name)) {
        enqueueSnackbar('Der Dateiname enthält nicht erlaubte Zeichen (z.B. ö)', {
          variant: 'error',
          persist: false,
        })
        return {
          code: 'non-latin-1-characters',
          message: 'Der Dateiname enthält nicht erlaubte Zeichen (z.B. ö)',
        }
      }

      return null
    },
  })

  const uploadModelMutation = useMutation(
    async () => {
      await uploadFile({
        url: config.apiRoutes.uploadCurrentModel(projectId),
        file: acceptedFiles[0],
        signal: controllerRef.current.signal,
      })
    },
    {
      onSuccess: async () => {
        setCurrentModel(
          (
            await getRequest<PlanarModel>({
              url: config.apiRoutes.getCurrentModelPlanar(projectId),
            })
          ).data,
        )
        enqueueSnackbar('Model Upload erfolgreich', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Model Upload fehlgeschlagen', { variant: 'error' })
      },
    },
  )

  return (
    <Stack direction="column" spacing={2}>
      <MuiBox
        sx={{
          padding: 3,
          border: theme => `1px dashed ${theme.palette.grey[isDragActive ? 800 : 400]}`,
          borderRadius: 1,
          bgcolor: 'grey.100',
          cursor: 'pointer',
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <Stack spacing={2} alignItems="center">
          <UploadFile
            sx={{
              marginTop: 0,
              width: (theme: Theme) => theme.spacing(8),
              height: (theme: Theme) => theme.spacing(8),
              color: 'primary.main',
            }}
          />

          <Typography textAlign="center">{'Neues model.json hochladen'}</Typography>

          {acceptedFiles[0] && (
            <Typography fontWeight="bold" fontStyle="italic">
              {acceptedFiles[0].name}
            </Typography>
          )}
        </Stack>
      </MuiBox>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<UploadFile />}
        onClick={() => uploadModelMutation.mutate()}
        loading={uploadModelMutation.isLoading}
      >
        Modell hochladen
      </Button>
    </Stack>
  )
}

export function SchnittbombeTab(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch'])

  const controllerRef = useRef(new AbortController())
  const { projectId } = useParams<{ projectId: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const [storeyHeights, setStoreyHeights] = useState<string[]>([])

  const { lockInterface, setLockInterface } = useArchViewStore()

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const clearIssues = useIssuesStore(state => state.clear)
  const [updateStoreyHeightsOptions, setUpdateStoreyHeightsOption] =
    useState<UpdateStoreyHeightsOptions>({
      translate_openings: false,
    } as UpdateStoreyHeightsOptions)

  useEffect(() => {
    if (currentModelPlanar) {
      const heights = reduce(
        // as the order cannot be guaranteed we need to sort by storey
        // boundaries first
        sortBy(Object.values(currentModelPlanar.storey_boundaries), el => el[0]),
        (col, [lowerBoundary, upperBoundary]) => {
          return [...col, upperBoundary - lowerBoundary]
        },
        [] as number[],
      )
      setStoreyHeights(heights.map(el => el.toString()))
    }
  }, [currentModelPlanar])

  const schnittbombeMutation = useMutation(
    async () => {
      clearIssues()

      setLockInterface(true)

      await applySchnittbombe(
        projectId,
        storeyHeights.map(height => parseFloat(height)),
        updateStoreyHeightsOptions,
        controllerRef.current.signal,
      )

      return getCurrentModel(projectId, controllerRef.current.signal)
    },
    {
      onSuccess: currenModelResponse => {
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.schnittbombeCalculation'), {
          variant: 'error',
          persist: true,
        })
      },
      onSettled: () => setLockInterface(false),
    },
  )

  function validateStoreyHeights(): boolean {
    return !storeyHeights.find(storeyHeight => !isNumber(storeyHeight.toString()))
  }

  function onChangeStoreyHeight(text: string, storey: number) {
    // convert commas back to decimals for calculation
    const value = text.replace(/,/g, '.')

    // do not allow more than one decimal point
    if ((value.match(/\./g) || []).length > 1) return
    // do not allow non-numerics
    if (!isNumber(value)) return

    setStoreyHeights(
      produce(storeyHeights, state => {
        state[storey] = value
      }),
    )
  }

  // calculate the summarized height of all previous storeys, if any of the storeys is
  // not a number the following building height will not be calculated
  const accumulatedStoreyHeights = useMemo(
    () =>
      storeyHeights.map((_, storey) =>
        storeyHeights.find(storeyHeight => !isNumber(storeyHeight))
          ? ''
          : storeyHeights
              .slice(0, storey + 1)
              .reduce((collector, row) => collector + parseFloat(row), 0)
              .toString()
              .replace(/\./g, ','),
      ),
    [storeyHeights],
  )

  return (
    <>
      <CustomSidebar>
        <Stack spacing={2} mt={1}>
          <Typography textAlign="center">
            {t('step5Arch:labels.determineStoreyHeights')}:
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('step5Arch:terms.storey')}</TableCell>
                  <TableCell>{t('step5Arch:terms.storeyHeight')}</TableCell>
                  <TableCell>{t('step5Arch:terms.buildingHeight')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeyHeights
                  .map((storeyHeight, storey) => (
                    <TableRow key={storey}>
                      <TableCell>
                        <Typography>{storey}</Typography>
                      </TableCell>
                      <TableCell>
                        <Input
                          // decimal commas should not be displayed as points
                          value={storeyHeight?.replace(/\./g, ',')}
                          error={!isNumber(storeyHeight.toString())}
                          onChange={event => onChangeStoreyHeight(event.target.value, storey)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{accumulatedStoreyHeights[storey]}</Typography>
                      </TableCell>
                      <TableCell>
                        <RemoveStoreyButton storey={storey} />
                      </TableCell>
                    </TableRow>
                  ))
                  .reverse()}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            onClick={() => schnittbombeMutation.mutate()}
            loading={schnittbombeMutation.isLoading}
            variant="contained"
            fullWidth
            disabled={!validateStoreyHeights() || lockInterface}
          >
            {t('step5Arch:terms.align')}
          </Button>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={updateStoreyHeightsOptions.translate_openings}
              onChange={event => {
                setUpdateStoreyHeightsOption({
                  ...updateStoreyHeightsOptions,
                  translate_openings: event.target.checked,
                })
              }}
            />
            <Typography sx={{ p: 1 }} noWrap>
              Öffnungen verschieben
            </Typography>
          </Stack>
          <Rotate90Button />
          <Divider />

          <UploadModelForm />
        </Stack>
      </CustomSidebar>

      <AbortionPrompt
        blocked={schnittbombeMutation.isLoading}
        onLeave={() => controllerRef.current.abort()}
        text={
          schnittbombeMutation.isLoading
            ? t('common:prompts.processNotCompleted')
            : t('step5Arch:prompts.goBack')
        }
        allowedPaths={schnittbombeMutation.isLoading ? [] : ['/']}
      />
    </>
  )
}
