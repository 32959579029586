import { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'

const SubmitButton = (): ReactElement => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  const { t } = useTranslation()

  return (
    <Button type="submit" variant="contained" loading={isSubmitting}>
      {t('actions.save')}
    </Button>
  )
}

export default SubmitButton
