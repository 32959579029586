import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Keyboard from '@mui/icons-material/Keyboard'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'

import { useHotkeysStore } from 'src/stores/hotkeysStore'

import HotKeyHeaderToggleButton from '../generic/HotKeyHeaderToggleButton'

export default function HotKeysWidget(): ReactElement {
  const { t } = useTranslation(['common', 'global'])

  const hotkeys = useHotkeysStore(state => state.hotkeys)

  const hotKeyModalOpen = useHotkeysStore(state => state.hotKeyModalOpen)
  const setHotKeyModalOpen = useHotkeysStore(state => state.setHotKeyModalOpen)

  return (
    <>
      <Tooltip title={`${t('global:hotkeys.hotkeys')} (i)`}>
        <HotKeyHeaderToggleButton
          value="active"
          size="small"
          selected={hotKeyModalOpen}
          onClick={() => setHotKeyModalOpen(true)}
          hotkeys="i"
          onHotkey={() => setHotKeyModalOpen(!hotKeyModalOpen)}
          hotkeysDeps={[hotKeyModalOpen]}
          hotkeyDescription={t('global:hotkeys.toggleHotkeysWindow')}
        >
          <Keyboard fontSize="small" />
        </HotKeyHeaderToggleButton>
      </Tooltip>

      <Dialog open={hotKeyModalOpen} onClose={() => setHotKeyModalOpen(false)}>
        <DialogTitle>{t('global:hotkeys.availableHotkeys')}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('global:hotkeys.description')}</TableCell>
                  <TableCell align="right">{t('global:hotkeys.hotkey')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hotkeys.map(hotkey => (
                  <TableRow
                    key={hotkey.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {hotkey.description}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {hotkey.key}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHotKeyModalOpen(false)}>{t('common:actions.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
