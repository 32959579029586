import { isUndefined } from 'lodash-es'

import { useCallback } from 'react'

import { useCameraStore } from '@modugen/scene/lib'

import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'

const useStoreySelection = () => {
  const setActiveStorey = useEditModelStore(state => state.setActiveStorey)
  const setSelectedRoofSlab = useEditModelStore(state => state.setSelectedRoofSlab)
  const setSelectedSlab = useEditModelStore(state => state.setSelectedSlab)

  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  return useCallback(
    (storey: string | undefined) => {
      setSelectedRoofSlab(undefined)
      setSelectedSlab(undefined)
      setActiveStorey(storey || undefined)
      if (!isUndefined(storey)) {
        setIsOrthographic(true)
      } else setIsOrthographic(false)
    },
    [setActiveStorey, setIsOrthographic, setSelectedRoofSlab, setSelectedSlab],
  )
}

export default useStoreySelection
