import React, { ReactElement } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { Button, CircularProgress } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import { config } from 'src/config/config'

import { MuiBox } from '../generic/MuiBox'

// the withAuthenticationRequired HOC provided by auth0 rerenders the app when
// the route gets changed (and all queries get refetched in this case). hence we
// implemented our own simple version of this
export default function ProtectedRoute(props: RouteProps): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  if (isLoading)
    return (
      <MuiBox flex={1} justifyContent="center" alignItems="center" display="flex">
        <CircularProgress />
      </MuiBox>
    )

  return !isAuthenticated && !config.loginDisabled ? (
    <MuiBox flex={1} justifyContent="center" alignItems="center" display="flex">
      <Button
        onClick={() =>
          loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } })
        }
        variant="contained"
      >
        Einloggen
      </Button>
    </MuiBox>
  ) : (
    <Route {...props} />
  )
}
