import { filter, find, isUndefined } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { ReactElement, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useGetSet, useLatest } from 'react-use'

import ArrowForward from '@mui/icons-material/ArrowForward'
import Crop54 from '@mui/icons-material/Crop54'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Tab, Tabs, Stack, Button, Typography, Tooltip, ButtonGroup } from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'

import { AbortionPrompt, useUnblockHistory } from 'src/components/generic/AbortionPrompt'
import { CustomSidebar } from 'src/components/generic/CustomSidebar'
import { MuiBox } from 'src/components/generic/MuiBox'
import { TabPanel } from 'src/components/generic/TabPanel'
import { HeaderPortalRight } from 'src/components/global/HeaderBar'
import RoofEditingScene from 'src/components/shared/interaction/RoofEditing/expose/components/RoofEditingScene'
import { config } from 'src/config/config'
import { postRequest } from 'src/utils/requests'

import { IfcElementsDetails } from '../../components/ByTypeSidebar/IfcElementsDetails'
import { CameraControls } from '../../components/controls/CameraControls'
import { FloorPlanController } from '../../controllers/FloorPlanController'
import { GeneratedModelController } from '../../controllers/GeneratedModelController'
import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import { useOnClickCurrentModel } from '../../controllers/GeneratedModelController/useOnClickGeneratedModel'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { SelectOpeningController } from '../../controllers/SelectOpeningController'
import { WallDrawOpeningController } from '../../controllers/WallDrawOpeningController'
import { WallDrawingController } from '../../controllers/WallDrawingController'
import { WallLengthController } from '../../controllers/WallLengthController'
import { WallMoveOrthogonalController } from '../../controllers/WallMoveOrthogonalController'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { setCurrentModel } from '../../queries/getAndSetCurrentModel'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useEditModelStore } from '../../stores/editModelStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import {
  useTypeVisibilityStoreByInstance,
  getHiddenIdsFromTypes,
  getHiddenIdsFromPlanarModel,
} from '../../stores/typeVisibilityStore'
import { PlanarModel } from '../../types'
import { ChangeWallPlacementTab } from './ChangeWallPlacementTab'
import { EditModelTab } from './EditModelTab'
import { ModelControls } from './ModelControls'
import { SchnittbombeTab } from './SchnittbombeTab'
import { useArchViewStore } from './archViewStore'
import { ChangeWallAxisPlacement } from './components/ChangeWallAxisPlacement'
import { ChangeWallPlacement } from './components/ChangeWallPlacement'
import { FloorplanControls } from './components/FloorplanControls'
import RoofEditing from './components/RoofEditing'

export enum ArchViewTabs {
  Alignment = 0,
  Edit = 1,
  Placement = 2,
}

export function SidebarLeft(): ReactElement {
  const { projectId } = useParams<{ projectId: string }>()
  const { t } = useTranslation(['step5Arch'])
  const { reassignedIfcElements } = useDerivedIfcData()
  const selectedWall = useEditModelStore(state => state.selectedWall)
  const selectedSlab = useEditModelStore(state => state.selectedSlab)
  const selectedRoofSlab = useEditModelStore(state => state.selectedRoofSlab)

  const selectedIfcElements = useMemo(
    () =>
      reassignedIfcElements?.filter(ifcElement =>
        [selectedWall, selectedSlab, selectedRoofSlab].includes(ifcElement.PSET_data.id),
      ),
    [reassignedIfcElements, selectedWall, selectedSlab, selectedRoofSlab],
  )

  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)
  const storey = useMemo(
    () => selectedWall && currentModelStoreyByGuid[selectedWall],
    [selectedWall, currentModelStoreyByGuid],
  )

  const setTabIndex = useEditModelStore(state => state.setActiveTabIndex)
  const setActiveStorey = useEditModelStore(state => state.setActiveStorey)

  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)
  const setSelectedSlab = useEditModelStore(state => state.setSelectedSlab)
  const setSelectedRoofSlab = useEditModelStore(state => state.setSelectedRoofSlab)
  const setEditMode = useEditModelStore(state => state.setEditMode)

  const { enqueueSnackbar } = useSnackbar()

  const removeWallMutation = useMutation(
    async (ifcId: string) =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRemoveWall(projectId),
          data: [ifcId],
        })
      ).data,
    {
      onSuccess: currenModelResponse => {
        setSelectedWall(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.removeWall'), { variant: 'error' })
      },
    },
  )

  const removeSlabMutation = useMutation(
    async (ifcId: string) =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRemoveSlab(projectId),
          data: [ifcId],
        })
      ).data,
    {
      onSuccess: currenModelResponse => {
        setSelectedSlab(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.removeElement'), { variant: 'error' })
      },
    },
  )

  const removeRoofSlabMutation = useMutation(
    async (ifcId: string) =>
      (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postRemoveRoofSlab(projectId),
          data: [ifcId],
        })
      ).data,
    {
      onSuccess: currenModelResponse => {
        setSelectedRoofSlab(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.removeElement'), { variant: 'error' })
      },
    },
  )

  if (selectedWall && storey) {
    return (
      <>
        <CustomSidebar title={t('step5Arch:labels.elementData') as string}>
          {selectedIfcElements?.length ? (
            <IfcElementsDetails
              elements={selectedIfcElements}
              selectableProperties={false}
              selectableChildren={false}
            />
          ) : (
            <Stack>
              <Typography variant="h6">{'Wand'}</Typography>
              <Typography>{`ID: ${selectedWall}`}</Typography>
              <Typography>{t('step5Arch:statusMessages.noDataForElement')}</Typography>
            </Stack>
          )}
          <Stack direction="column" spacing={2}>
            <ChangeWallPlacement />
            <ChangeWallAxisPlacement />
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                setActiveStorey(storey)
                setTabIndex(1)
                setIsOrthographic(true)
              }}
            >
              {t('step5Arch:actions.jumpToElement')}
            </Button>
            <Stack direction="column" spacing={2} alignItems="center">
              <ButtonGroup>
                <Button
                  onClick={() => removeWallMutation.mutate(selectedWall)}
                  loading={removeWallMutation.isLoading}
                  variant="outlined"
                >
                  <Tooltip title={t('step5Arch:editModelTab.remove')}>
                    <DeleteOutlineIcon />
                  </Tooltip>
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setTabIndex(1)
                    setActiveStorey(storey)
                    setEditMode('opening')
                    setIsOrthographic(true)
                  }}
                >
                  <Tooltip title={t('step5Arch:editModelTab.drawOpening')}>
                    <Crop54 />
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </CustomSidebar>
      </>
    )
  } else if (selectedSlab) {
    return (
      <>
        <CustomSidebar title={t('step5Arch:labels.elementData') as string}>
          {selectedIfcElements?.length ? (
            <IfcElementsDetails
              elements={selectedIfcElements}
              selectableProperties={false}
              selectableChildren={false}
            />
          ) : (
            <Typography>{t('step5Arch:statusMessages.noDataForElement')}</Typography>
          )}
          <Button
            onClick={() => removeSlabMutation.mutate(selectedSlab)}
            loading={removeSlabMutation.isLoading}
            variant="outlined"
          >
            <Tooltip title={t('step5Arch:editModelTab.remove')}>
              <DeleteOutlineIcon />
            </Tooltip>
          </Button>
        </CustomSidebar>
      </>
    )
  } else if (selectedRoofSlab) {
    return (
      <>
        <CustomSidebar title={t('step5Arch:labels.elementData') as string}>
          <Stack direction="column" spacing={2}>
            {selectedIfcElements?.length ? (
              <IfcElementsDetails
                elements={selectedIfcElements}
                selectableProperties={false}
                selectableChildren={false}
              />
            ) : (
              <Typography>{t('step5Arch:statusMessages.noDataForElement')}</Typography>
            )}
            <Button
              onClick={() => removeRoofSlabMutation.mutate(selectedRoofSlab)}
              loading={removeRoofSlabMutation.isLoading}
              variant="outlined"
            >
              <Tooltip title={t('step5Arch:editModelTab.remove')}>
                <DeleteOutlineIcon />
              </Tooltip>
            </Button>

            <RoofEditing slabGuid={selectedRoofSlab} />
          </Stack>
        </CustomSidebar>
      </>
    )
  } else {
    return <></>
  }
}

export function Scene(): ReactElement | null {
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  const ifcElements = useIfcElementsStore(state => state.ifcElements)

  const { ifcIdsByType, notExportedIds } = useDerivedIfcData()
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const mergedFilteredIds = useDerivedIfcDataStore(state => state.mergedFilteredIds)
  useShowOpenings(false)

  const selectedWall = useEditModelStore(state => state.selectedWall)
  const setSelectedWall = useEditModelStore(state => state.setSelectedWall)

  const selectedSlab = useEditModelStore(state => state.selectedSlab)
  const setSelectedSlab = useEditModelStore(state => state.setSelectedSlab)

  const selectedRoofSlab = useEditModelStore(state => state.selectedRoofSlab)
  const setSelectedRoofSlab = useEditModelStore(state => state.setSelectedRoofSlab)

  const selectedWallsForPlacement = useEditModelStore(state => state.selectedWallsForPlacement)
  const setSelectedWallsForPlacement = useEditModelStore(
    state => state.setSelectedWallsForPlacement,
  )

  const editMode = useEditModelStore(state => state.editMode)
  const activeTabIndex = useEditModelStore(state => state.activeTabIndex)

  const isMovingActive = useEditModelStore(state => state.isMovingActive)
  const isExtendingActive = useEditModelStore(state => state.isExtendingActive)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const deselectPlanarElements = useGeneratedModelStore(state => state.deselectElements)
  const selectPlanarElement = useGeneratedModelStore(state => state.selectElement)

  const setHiddenIdsGltfModel = useIfcElementsStore(state => state.setHiddenIdsGltfModel)
  const gltfVisibilityByType = useTypeVisibilityStoreByInstance(
    'gltf',
    'header',
  )(state => state.visibilityByType)

  const setHiddenIdsCurrentModel = useIfcElementsStore(state => state.setHiddenIdsCurrentModel)
  const currentVisibilityByType = useTypeVisibilityStoreByInstance(
    'current',
    'header',
  )(state => state.visibilityByType)

  const ifcElementIds = useMemo(() => ifcElements?.map(el => el.PSET_data.id), [ifcElements])
  const planarModelIds = useMemo(() => {
    if (!currentModelPlanar) return []
    const wallIds = currentModelPlanar.walls.map(wall => wall.guid)
    const slabIds = currentModelPlanar.slabs.map(slab => slab.guid)
    const roofSlabIds = currentModelPlanar.roof_slabs.map(roofSlab => roofSlab.guid)

    return [...wallIds, ...slabIds, ...roofSlabIds]
  }, [currentModelPlanar])

  const allIdsExceptSelectedWallInOpeningMode = useMemo(
    () =>
      !!activeDrawingStorey && editMode === 'opening'
        ? [
            ...filter(ifcElementIds, id => id !== selectedWall),
            ...filter(planarModelIds, id => id !== selectedWall),
          ]
        : [],
    [activeDrawingStorey, editMode, ifcElementIds, planarModelIds, selectedWall],
  )

  const [getIsRoofEditing, setIsRoofEditing] = useGetSet(false)

  useEffect(() => {
    setHiddenIdsGltfModel(
      new Set([
        ...getHiddenIdsFromTypes(ifcIdsByType, gltfVisibilityByType, trueIfcGroups),
        ...notExportedIds,
        ...mergedFilteredIds,
        ...allIdsExceptSelectedWallInOpeningMode,
      ]),
    )

    if (currentModelPlanar) {
      setHiddenIdsCurrentModel(
        new Set([
          ...getHiddenIdsFromPlanarModel(currentVisibilityByType, currentModelPlanar),
          ...allIdsExceptSelectedWallInOpeningMode,
        ]),
      )
    }
  }, [
    ifcIdsByType,
    gltfVisibilityByType,
    currentVisibilityByType,
    allIdsExceptSelectedWallInOpeningMode,
    setHiddenIdsGltfModel,
    trueIfcGroups,
    notExportedIds,
    mergedFilteredIds,
    currentModelPlanar,
    setHiddenIdsCurrentModel,
  ])

  const latestModel = useLatest(currentModelPlanar)

  useOnClickCurrentModel(event => {
    if (getIsRoofEditing()) return

    const guid = event.object.name

    const isWall = find(latestModel.current?.walls, ['guid', guid])
    if (isWall) {
      setSelectedSlab(undefined)
      setSelectedRoofSlab(undefined)
      setSelectedWall(guid)
    }

    const isSlab = find(latestModel.current?.slabs, ['guid', guid])
    if (isSlab) {
      setSelectedWall(undefined)
      setSelectedRoofSlab(undefined)
      setSelectedSlab(guid)
    }

    const isRoofSlab = find(latestModel.current?.roof_slabs, ['guid', guid])
    if (isRoofSlab) {
      setSelectedWall(undefined)
      setSelectedSlab(undefined)
      setSelectedRoofSlab(guid)
    }
  })

  useEffect(() => {
    if (selectedWall && editMode !== 'opening') {
      deselectPlanarElements()
      selectPlanarElement(selectedWall)
    } else if (selectedSlab) {
      deselectPlanarElements()
      selectPlanarElement(selectedSlab)
    } else if (selectedRoofSlab) {
      deselectPlanarElements()
      selectPlanarElement(selectedRoofSlab)
    }

    return deselectPlanarElements
  }, [
    selectedWall,
    selectedSlab,
    selectedRoofSlab,
    editMode,
    deselectPlanarElements,
    selectPlanarElement,
  ])

  if (!!selectedWall && editMode === 'opening') {
    return (
      <>
        <GeneratedModelController />
        <WallDrawOpeningController guid={selectedWall} />
        <SelectOpeningController wallGuid={selectedWall} />
      </>
    )
  }

  if (!activeDrawingStorey) {
    return (
      <>
        <GeneratedModelController />
        {selectedRoofSlab && (
          <RoofEditingScene
            onEditStart={() => setIsRoofEditing(true)}
            onEditEnd={() => setIsRoofEditing(false)}
          />
        )}
      </>
    )
  }

  if (activeTabIndex === ArchViewTabs.Placement)
    return (
      <FloorPlanController
        highlightedWalls={selectedWallsForPlacement}
        wallsSelectable={!isTapelineActive}
        onClickWall={wall => {
          if (selectedWallsForPlacement.includes(wall)) {
            const filteredWalls = filter(selectedWallsForPlacement, wall)
            setSelectedWallsForPlacement(filteredWalls)
          } else {
            setSelectedWallsForPlacement([...selectedWallsForPlacement, wall])
          }
        }}
      />
    )

  const isEditingModelActive = editMode === 'draw' || isMovingActive || isExtendingActive

  return (
    <>
      <FloorPlanController
        excludeGuids={
          editMode === 'delete' || editMode === 'placement'
            ? undefined
            : activeTabIndex === ArchViewTabs.Edit
            ? [selectedWall as string]
            : undefined
        }
        highlightedWalls={[selectedWall as string]}
        wallsSelectable={!isTapelineActive && !isEditingModelActive}
        onClickWall={setSelectedWall}
      />
      {editMode === 'draw' && <WallDrawingController />}
      {selectedWall && editMode === 'change' && <WallLengthController guid={selectedWall} />}
      {selectedWall && editMode === 'move' && <WallMoveOrthogonalController guid={selectedWall} />}
      {selectedWall && editMode === 'opening' && <WallDrawOpeningController guid={selectedWall} />}
    </>
  )
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch'])

  const history = useHistory()
  const unblockHistory = useUnblockHistory()

  const controllerRef = useRef(new AbortController())
  const { projectId } = useParams<{ projectId: string }>()

  const { lockInterface } = useArchViewStore()

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const tabIndex = useEditModelStore(state => state.activeTabIndex)
  const setTabIndex = useEditModelStore(state => state.setActiveTabIndex)

  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  const setEditMode = useEditModelStore(state => state.setEditMode)
  const editMode = useEditModelStore(state => state.editMode)

  useEffect(() => {
    if (tabIndex === ArchViewTabs.Alignment || tabIndex === ArchViewTabs.Placement) {
      setEditMode(undefined)
    } else {
      !editMode && setEditMode('delete')
    }
  }, [editMode, setEditMode, tabIndex])

  return (
    <CustomSidebar
      title={t('step5Arch:labels.adaptModel')}
      bottom={
        <Button
          onClick={() => {
            unblockHistory()
            history.push(`/ifc-importer/${projectId}/step-6-export`)
          }}
          startIcon={<ArrowForward />}
          variant="contained"
          fullWidth
          disabled={!currentModelPlanar || lockInterface}
        >
          {t('common:actions.confirmAndContinue')}
        </Button>
      }
    >
      <Stack>
        <MuiBox flexShrink={0} borderBottom={1} borderColor="grey.200">
          <Tabs
            value={tabIndex}
            onChange={(_: unknown, val: ArchViewTabs) => setTabIndex(val)}
            variant="fullWidth"
            sx={{
              '.MuiTab-root': {
                fontSize: '0.7rem',
              },
            }}
          >
            <Tab label={t('step5Arch:tabs.alignment')} />
            <Tab label={t('step5Arch:tabs.edit')} />
            <Tab label={t('step5Arch:tabs.placement')} />
          </Tabs>
        </MuiBox>

        <TabPanel value={tabIndex} flexGrow={1} index={0} pt={1}>
          <SchnittbombeTab />
        </TabPanel>
        <TabPanel value={tabIndex} flexGrow={1} index={1} pt={1}>
          <EditModelTab />
        </TabPanel>
        <TabPanel value={tabIndex} flexGrow={1} index={2} pt={1}>
          <ChangeWallPlacementTab />
        </TabPanel>
      </Stack>

      <HeaderPortalRight>
        <Stack direction="row" spacing={1}>
          <ModelControls />
          <CameraControls />
          {!isUndefined(activeDrawingStorey) && <FloorplanControls />}
        </Stack>
      </HeaderPortalRight>

      <AbortionPrompt
        blocked
        onLeave={() => controllerRef.current.abort()}
        text={t('step5Arch:prompts.goBack')}
        allowedPaths={['/', `/ifc-importer/${projectId}/step-6-export`]}
      />
    </CustomSidebar>
  )
}
