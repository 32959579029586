import React, { ReactElement, ReactNode, useEffect, useRef, useState, MouseEvent } from 'react'

import { Paper, Theme, Typography } from '@mui/material'

import { HeaderButton } from 'src/components/generic/HeaderButtonGroup'

export function ControlsDropDown({
  expandTo = 'right',
  title,
  toggleType = 'click',
  ...props
}: {
  title?: string
  children: ReactNode
  icon: ReactNode
  expandTo?: 'right' | 'left'
  toggleType?: 'hover' | 'click'
}): ReactElement {
  const [open, setOpen] = useState(false)
  const targetRef = useRef<unknown>(null)

  useEffect(() => {
    const close = (event: MouseEvent) => {
      if (event.target !== targetRef.current) {
        setOpen(false)
      }
    }

    // @ts-expect-error
    document.addEventListener('click', close)
    // @ts-expect-error
    return () => document.removeEventListener('click', close)
  }, [])

  return (
    <HeaderButton
      sx={{
        '&:hover': {
          '.controlsContainer': {
            display: toggleType === 'hover' ? 'block' : undefined,
          },
        },
        textTransform: 'none',
        pl: 0,
        pr: 0,
      }}
      // as the dropdown contains buttons itself, the parent element cannot be
      // a button. we basically just want the style of a HeaderButton but none
      // of its functionality
      component="div"
      disableRipple
      onClick={(event: MouseEvent) => {
        targetRef.current = event.target
        setOpen(!open)
      }}
    >
      {props.icon}

      <Paper
        className="controlsContainer"
        sx={{
          display: toggleType === 'hover' ? 'none' : open ? 'block' : 'none',
          position: 'absolute',
          right: expandTo === 'left' ? 0 : undefined,
          left: expandTo === 'right' ? 0 : undefined,
          top: (theme: Theme) => theme.spacing(3.5),
          p: 1.25,
        }}
        onClick={(e: MouseEvent) => e.stopPropagation()}
      >
        {title && (
          <Typography variant="h6" sx={{ mb: 1.5, textAlign: 'center' }}>
            {title}
          </Typography>
        )}

        {props.children}
      </Paper>
    </HeaderButton>
  )
}
