import React, { ReactElement, ReactNode } from 'react'

import {
  FormControl as MuiFormControl,
  Typography,
  FormHelperText,
  CircularProgress,
  Theme,
} from '@mui/material'

import { InfoTooltip } from 'src/components/generic/feedback'

import { MuiBox } from '../../MuiBox'

interface Props {
  label?: string | ReactNode
  children: ReactNode
  error?: string
  isLoading?: boolean
  tooltip?: TooltipContents
}

// Component copied from modugen static
const FormControl = ({
  label,
  children,
  error,
  isLoading = false,
  tooltip,
}: Props): ReactElement => {
  return (
    <MuiFormControl
      fullWidth
      sx={{
        '& .Mui-error': {
          marginLeft: 0,
        },
      }}
    >
      {label && (
        <MuiBox display="flex" alignItems="center">
          <Typography
            sx={(theme: Theme) => ({
              fontWeight: 500,
              fontSize: 14,
              marginBottom: theme.spacing(0.5),
              marginRight: theme.spacing(1),
            })}
          >
            {label}
          </Typography>
          {tooltip && <InfoTooltip {...tooltip} />}
          {isLoading && <CircularProgress size={12} />}
        </MuiBox>
      )}
      {children}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </MuiFormControl>
  )
}

export default FormControl
