import { isUndefined } from 'lodash-es'

import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CheckCircle from '@mui/icons-material/CheckCircle'
import { Divider, SvgIcon } from '@mui/material'

import { ReactComponent as IFCLogo } from 'src/assets/ifc_icon.svg'
import { HeaderButtonGroup } from 'src/components/generic/HeaderButtonGroup'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'

import { ArchViewTabs } from '..'
import { useGeneratedModelStore } from '../../../controllers/GeneratedModelController/generatedModelStore'
import { useGltfModelStore } from '../../../controllers/GltfModelController/gltfModelStore'
import { useTypeVisibilityStoreByInstance } from '../../../stores/typeVisibilityStore'
import { ControlsDropDown } from './ControlsDropDown'
import { CurrentModelStoreySelect } from './CurrentModelStoreySelect'
import { VisibilitySwitches } from './VisibilitySwitches'

export function ModelControls(): ReactElement {
  const { setVisibilityForAllTypes: setVisibilityForAllTypesGltfModel } =
    useTypeVisibilityStoreByInstance('gltf', 'header')()

  const { setVisibilityForAllTypes: setVisibilityForAllTypesCurrentModel } =
    useTypeVisibilityStoreByInstance('current', 'header')()

  const setGltfModelTranslucent = useGltfModelStore(state => state.setGltfModelTranslucent)
  const setCurrentModelTranslucent = useGeneratedModelStore(
    state => state.setCurrentModelTranslucent,
  )

  const editMode = useEditModelStore(state => state.editMode)
  const activeTabIndex = useEditModelStore(state => state.activeTabIndex)
  const activeStorey = useEditModelStore(state => state.activeStorey)

  // only allow using element visibility controls when creating openings as
  // right now we do not have storey information about the elements in gltf
  // model and therefore cannot show the correct elements in floorplan view
  // (only the ones of selected storey)
  const elementVisibilityDisabled = useMemo(() => {
    if (!isUndefined(activeStorey)) return true
    if (!!activeStorey && !!editMode && editMode !== 'opening') return true
    if (!!activeStorey && activeTabIndex === ArchViewTabs.Placement) return true
  }, [editMode, activeTabIndex, activeStorey])

  useEffect(() => {
    return () => {
      setVisibilityForAllTypesGltfModel(true)
      setVisibilityForAllTypesCurrentModel(true)

      setGltfModelTranslucent(false)
      setCurrentModelTranslucent(false)
    }
  }, [])

  return (
    <HeaderButtonGroup disabled={elementVisibilityDisabled} size="large">
      <GltfModelControls />
      <CurrentModelControls />
    </HeaderButtonGroup>
  )
}

function GltfModelControls(): ReactElement {
  const { t } = useTranslation('step5Arch')
  const { setTypeVisibility, visibilityByType, setVisibilityForAllTypes } =
    useTypeVisibilityStoreByInstance('gltf', 'header')()

  const gltfModelTranslucent = useGltfModelStore(state => state.gltfModelTranslucent)
  const setGltfModelTranslucent = useGltfModelStore(state => state.setGltfModelTranslucent)

  return (
    <ControlsDropDown
      title={t('modelControls.original')}
      icon={
        <SvgIcon fontSize="small" viewBox="0 0 30 30" style={{ backgroundColor: 'transparent' }}>
          <IFCLogo style={{ width: 'inherit', height: 'inherit' }} />
        </SvgIcon>
      }
      expandTo="left"
    >
      <VisibilitySwitches
        modelVisible={Object.values(visibilityByType).some(isTypeVisible => isTypeVisible)}
        onChangeModelVisibility={visible => setVisibilityForAllTypes(visible)}
        typeVisibility={visibilityByType}
        onChangeTypeVisibility={(type, visibility) => setTypeVisibility(type, visibility)}
        modelTranslucent={gltfModelTranslucent}
        setModelTranslucent={setGltfModelTranslucent}
      />
    </ControlsDropDown>
  )
}

function CurrentModelControls(): ReactElement | null {
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const { setTypeVisibility, visibilityByType, setVisibilityForAllTypes } =
    useTypeVisibilityStoreByInstance('current', 'header')()

  const currentModelTranslucent = useGeneratedModelStore(state => state.currentModelTranslucent)
  const setCurrentModelTranslucent = useGeneratedModelStore(
    state => state.setCurrentModelTranslucent,
  )

  const { t } = useTranslation('step5Arch')

  return currentModelPlanar ? (
    <ControlsDropDown
      title={t('modelControls.modelCurrent')}
      icon={<CheckCircle fontSize="small" />}
      expandTo="left"
    >
      <CurrentModelStoreySelect />

      <Divider sx={{ mt: 1.25, mb: 0.75 }} />

      <VisibilitySwitches
        modelVisible={Object.values(visibilityByType).some(isTypeVisible => isTypeVisible)}
        onChangeModelVisibility={visible => setVisibilityForAllTypes(visible)}
        typeVisibility={visibilityByType}
        onChangeTypeVisibility={(group, visibility) => setTypeVisibility(group, visibility)}
        modelTranslucent={currentModelTranslucent}
        setModelTranslucent={setCurrentModelTranslucent}
      />
    </ControlsDropDown>
  ) : null
}
