import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { nthLooped } from 'src/components/shared/interaction/RoofEditing/internal/utils'

import { PlanarRoofSlab, PlanarSlab } from '../types'

export function projectSlab(slab: PlanarRoofSlab | PlanarSlab) {
  return {
    guid: slab.guid,
    points: slab.shape.points.map((p, i) => {
      const nextPoint = nthLooped(slab.shape.points, i + 1)
      const next = new ImmutableVector3(nextPoint.x, nextPoint.y, nextPoint.z)
      return [new ImmutableVector3(p.x, p.y, p.z), next]
    }),
  }
}
