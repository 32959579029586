/**
 * Defines the minimun distance of a point to the plane of a roof slab
 * Copied from backend
 */
export const roofPlaneThreshold = 1e-10
export const roofDrawingDecimals = 2
/**
 * This is the number roof extending will round z to in order to have similar z coordinate4s
 */
export const roofExtendingZPrecision = 10
