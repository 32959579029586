import { filter, toNumber } from 'lodash-es'
import { Vector3 } from 'three'

import { useMemo } from 'react'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Opening, PlanarWall, WallPlacement } from '../../types'
import { projectOpening } from '../../utils/projectOpening'
import { projectPlanarWall } from '../../utils/projectPlanarWall'

const useProjectedWalls = (
  planarWalls: (PlanarWall & { storey: string })[],
  excludeGuids: string[],
): [
  {
    guid: string
    placement: WallPlacement
    points: ImmutableVector3[]
    storey: string
  }[],
  {
    guid: string
    points: ImmutableVector3[]
    pointsV: Vector3[]
    storey: string
    placement: WallPlacement
  }[],
] => {
  return useMemo(() => {
    const visibleWalls = filter(planarWalls, wall => !excludeGuids.includes(wall.guid))

    const projectedWalls = visibleWalls.map(wall => ({
      ...projectPlanarWall(wall, toNumber(wall.storey)),
      storey: wall.storey,
    }))

    const projectedOpenings = visibleWalls.reduce((collector, wall) => {
      collector.push(
        ...wall.openings.map((opening: Opening) => ({
          ...projectOpening(opening, toNumber(wall.storey)),
          storey: wall.storey,
          placement: wall.placement,
        })),
      )

      return collector
    }, [] as { guid: string; points: ImmutableVector3[]; pointsV: Vector3[]; storey: string; placement: WallPlacement }[])

    return [projectedWalls, projectedOpenings]
  }, [planarWalls, excludeGuids])
}

export default useProjectedWalls
