import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next } from 'react-i18next'

import { config } from 'src/config/config'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'de',
    fallbackLng: 'de',
    debug: !import.meta.env.PROD,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    defaultNS: 'common',

    resources: config.i18n.ressources,
  })

export default i18n
