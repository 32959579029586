import { enableMapSet } from 'immer'
import { SnackbarKey, SnackbarProvider } from 'notistack'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { CssBaseline, GlobalStyles, Button } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@modugen/scene/lib/styles.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import ErrorFallBackUI from './components/generic/ErrorFallBackUi'
import { AuthenticationProvider } from './components/providers/AuthenticationProvider'
import { config } from './config/config'
import './localization/i18n'
import { App } from './pages/App'
import { globalStyles } from './styles/globalStyles'
import { theme } from './styles/theme'

LicenseInfo.setLicenseKey(config.muiXLicense as string)

const queryClient = new QueryClient()

// tell immer to create a new Set / Map when mutating an existing Set / Map
// within produce(), more context here: https://immerjs.github.io/immer/map-set
enableMapSet()

const notistackRef = React.createRef<SnackbarProvider>()
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef?.current?.closeSnackbar(key)
}

const isProductionOrReleaseEnvironment =
  config.environment === 'production' || config.environment === 'release-candidate'

Sentry.init({
  dsn: config.sentryDSN,
  tunnel: `${config.apiURL}/sentry-tunnel`,
  integrations: [new BrowserTracing()],
  environment: config.environment,
  tracesSampleRate: isProductionOrReleaseEnvironment ? 0.2 : 1.0,
  enabled: import.meta.env.PROD,
})

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => <ErrorFallBackUI resetError={resetError} />}
      showDialog
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        action={key => (
          <Button sx={{ color: 'white' }} onClick={onClickDismiss(key)}>
            Ok
          </Button>
        )}
        ref={notistackRef}
        maxSnack={3}
      >
        {/* @ts-ignore */}
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthenticationProvider>
              <CssBaseline />
              <GlobalStyles styles={globalStyles} />
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </AuthenticationProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </SnackbarProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
