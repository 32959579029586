import React from 'react'
import { useTranslation } from 'react-i18next'

import PictureInPicture from '@mui/icons-material/PictureInPicture'
import { Tooltip } from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'

import { HeaderToggleButton } from 'src/components/generic/HeaderToggleButtonGroup'

import { useEditModelStore } from '../../stores/editModelStore'

export function PanControls() {
  const { t } = useTranslation('ifcImporter')

  const resetPan = useCameraStore(state => state.resetPan)
  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  return (
    <Tooltip title={t('pan.resetCamera')}>
      <HeaderToggleButton
        value="visible"
        size="small"
        onClick={resetPan}
        selected={false}
        disabled={!!activeDrawingStorey}
      >
        <PictureInPicture fontSize="small" />
      </HeaderToggleButton>
    </Tooltip>
  )
}
