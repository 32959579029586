import axios from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'

import React, { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { ArrowLeft, ArrowRight, FormatAlignCenter } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Stack, Typography, ButtonGroup, TextField, InputAdornment } from '@mui/material'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Toolbox } from 'src/components/generic/Toolbox'
import { config } from 'src/config/config'
import { useGeneratedModelStore } from 'src/pages/IfcImporter/controllers/GeneratedModelController/generatedModelStore'
import { setCurrentModel } from 'src/pages/IfcImporter/queries/getAndSetCurrentModel'
import { useEditModelStore } from 'src/pages/IfcImporter/stores/editModelStore'
import { PlanarModel, WallAxisPlacement } from 'src/pages/IfcImporter/types'
import { projectPlanarWall } from 'src/pages/IfcImporter/utils/projectPlanarWall'
import { patchRequest, postRequest } from 'src/utils/requests'

import { ChangeWallAxisPlacementSchema } from './schema'

export function ChangeWallAxisPlacement() {
  const { projectId } = useParams<{ projectId: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const [displacement, setDisplacement] = useState<string>('0')

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const selectedWall = useEditModelStore(state => state.selectedWall)

  const wall = useMemo(
    () => find(currentModelPlanar?.walls, { guid: selectedWall }),
    [selectedWall, currentModelPlanar],
  )

  const changeAxisPlacement = useMutation(
    async (axis_placement: WallAxisPlacement) => {
      if (!wall?.guid) {
        throw new Error('No wall selected')
      }

      const data: ChangeWallAxisPlacementSchema = {
        element_guid: wall.guid,
        axis_placement,
      }

      return (
        await patchRequest<PlanarModel>({
          url: config.apiRoutes.patchWallAxisPlacement(
            projectId,
            data.element_guid,
            data.axis_placement,
          ),
          data: [data],
        })
      ).data
    },
    {
      onSuccess: newModel => {
        enqueueSnackbar('Wandachse erfolgreich geändert', { variant: 'success' })
        setCurrentModel(newModel)
      },
      onError: (error: unknown) => {
        let errorMessage =
          'Wandachse konnte nicht geändert werden. Dies könnte daran liegen, dass die Wand keine Referenz zur IFC Wand besitzt.'

        if (
          axios.isAxiosError(error) &&
          error.response?.data &&
          typeof error.response.data === 'object' &&
          error.response.data !== null &&
          'detail' in error.response.data
        ) {
          errorMessage = error.response.data.detail as string
        }

        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const translateWall = useMutation(
    async (displacementValue: number) => {
      if (!wall?.guid) {
        throw new Error('No wall selected')
      }

      // Get wall points
      const projectedWall = projectPlanarWall(wall)
      const start = projectedWall.points[0]
      const end = projectedWall.points[1]

      // Calculate wall direction vector
      const wallDirection = end.sub(start).normalize()

      // Calculate normal vector using cross product with up vector (0,0,1)
      const upVector = new ImmutableVector3(0, 0, 1)
      const wallNormal = upVector.cross(wallDirection).normalize()

      // Apply translation along the normal direction
      const newStart = start.addScaledVector(wallNormal, displacementValue)
      const newEnd = end.addScaledVector(wallNormal, displacementValue)

      return (
        await postRequest<PlanarModel>({
          url: config.apiRoutes.postTranslateWall(projectId),
          data: {
            element_guid: wall.guid,
            new_start: {
              x: newStart.x,
              y: newStart.y,
            },
            new_end: {
              x: newEnd.x,
              y: newEnd.y,
            },
          },
        })
      ).data
    },
    {
      onSuccess: newModel => {
        enqueueSnackbar('Wand erfolgreich verschoben', { variant: 'success' })
        setCurrentModel(newModel)
      },
      onError: (error: unknown) => {
        let errorMessage = 'Wand konnte nicht verschoben werden.'

        if (
          axios.isAxiosError(error) &&
          error.response?.data &&
          typeof error.response.data === 'object' &&
          error.response.data !== null &&
          'detail' in error.response.data
        ) {
          errorMessage = error.response.data.detail as string
        }

        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const isLoading = useMemo(
    () => changeAxisPlacement.isLoading || translateWall.isLoading,
    [changeAxisPlacement.isLoading, translateWall.isLoading],
  )

  const isDisplacementValid = useMemo(() => {
    const value = parseFloat(displacement)
    return !isNaN(value) && value !== 0
  }, [displacement])

  const handleClick = (axis_placement: 'LEFT' | 'CENTER' | 'RIGHT') => {
    if (isLoading) return
    changeAxisPlacement.mutate(axis_placement)
  }

  const handleTranslate = () => {
    if (isLoading) return
    const displacementValue = parseFloat(displacement)

    if (isNaN(displacementValue)) {
      enqueueSnackbar('Bitte geben Sie einen gültigen Zahlenwert ein', { variant: 'error' })
      return
    }

    if (displacementValue === 0) {
      enqueueSnackbar('Der Verschiebungswert darf nicht 0 sein', { variant: 'error' })
      return
    }

    translateWall.mutate(displacementValue)
  }

  return wall ? (
    <Toolbox pt={1} pb={1}>
      <Stack direction="column" spacing={2} alignItems="center">
        <Typography textAlign={'center'}>Wandachse</Typography>
        <ButtonGroup variant="contained" size="small" fullWidth>
          <LoadingButton
            loading={isLoading}
            onClick={() => handleClick('LEFT')}
            variant="contained"
            startIcon={<ArrowLeft />}
            sx={{ flex: 1 }}
          >
            Links
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            onClick={() => handleClick('CENTER')}
            variant="contained"
            startIcon={<FormatAlignCenter />}
            sx={{ flex: 1 }}
          >
            Mitte
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            onClick={() => handleClick('RIGHT')}
            variant="contained"
            startIcon={<ArrowRight />}
            sx={{ flex: 1 }}
          >
            Rechts
          </LoadingButton>
        </ButtonGroup>
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <TextField
            label="(otho) verschieben"
            id="displacement"
            value={displacement}
            onChange={e => setDisplacement(e.target.value)}
            type="number"
            size="small"
            inputProps={{
              step: 0.1,
              style: { textAlign: 'center' },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="start">m</InputAdornment>,
              startAdornment: <InputAdornment position="start">um</InputAdornment>,
            }}
            fullWidth
            disabled={isLoading}
          />
          <LoadingButton
            loading={isLoading}
            onClick={handleTranslate}
            variant="contained"
            disabled={isLoading || !isDisplacementValid}
          >
            Verschieben
          </LoadingButton>
        </Stack>
      </Stack>
    </Toolbox>
  ) : (
    <Typography textAlign="center">{'Wand auswählen'}</Typography>
  )
}
