import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { config } from 'src/config/config'
import i18n from 'src/localization/i18n'
import { timeout } from 'src/utils/misc'

function apiRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  const response = axios.request<T>({
    method: 'GET',
    baseURL: config.apiURL,
    ...request,
    headers: {
      ...request.headers,
    },
  })

  return response
}

export function getRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return apiRequest<T>(request)
}

export function postRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return apiRequest<T>({
    method: 'POST',
    ...request,
  })
}

export function patchRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return apiRequest<T>({
    method: 'PATCH',
    ...request,
  })
}

export function deleteRequest<T>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  return apiRequest<T>({
    method: 'DELETE',
    ...request,
  })
}

export async function retryUntilSuccessOrFailure<T>(
  request: AxiosRequestConfig,
  maxRetries = 60 * 10,
  retryInterval = 1000,
): Promise<AxiosResponse<T>> {
  let response
  let retries = 0

  while (retries < maxRetries && !response) {
    try {
      response = await getRequest<T>(request)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status !== 420) {
          throw new Error(i18n.t('common:errors.taskExecution'))
        }
      }
    }

    retries += 1
    await timeout(retryInterval)
  }

  if (!response) throw new Error(i18n.t('common:errors.retriesExhausted'))

  return response
}

export function uploadFile({
  url,
  file,
  signal,
}: {
  url: string
  file: File
  signal?: AbortSignal
}): Promise<AxiosResponse<unknown>> {
  const formData = new FormData()
  formData.append('upload', file)

  return postRequest({
    url,
    data: formData,
    signal,
  })
}
