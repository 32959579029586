import { useSnackbar } from 'notistack'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { Button, Grid, Stack, Theme, Typography, useTheme } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import { MuiBox as Box } from 'src/components/generic/MuiBox'
import { config } from 'src/config/config'

import IconCard from './components/IconCard'
import { cardContents } from './constants'

const Authentication = (): ReactElement => {
  const { loginWithRedirect, isLoading } = useAuth0()
  const { t } = useTranslation('authentication')

  const { modugenRegisterURL } = config
  const history = useHistory()
  const location = useLocation<{ from?: Location }>()
  const { enqueueSnackbar } = useSnackbar()

  const theme = useTheme()

  const { mutate } = useMutation(async () => loginWithRedirect(), {
    onSuccess: () => {
      history.push(location.state.from || '/')
    },
    onError: error => {
      // required as loginWithRedirect throws the following error
      // TypeError: Cannot read properties of undefined (reading 'from')
      if (!(error instanceof TypeError)) {
        enqueueSnackbar(t('errors.userAuthentication'), { variant: 'error' })
      }
    },
  })

  return (
    <Grid container sx={{ minHeight: `calc(100vh - ${theme.spacing(8)})` }}>
      <Grid item xs={9}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={({ spacing }: Theme) => ({
              padding: spacing(3),
              paddingTop: spacing(10),
              textAlign: 'center',
            })}
          >
            <Typography variant="h2">ModuGen Importer</Typography>
            <Typography color={theme.palette.grey[700]} variant="h4">
              {t('terms.theSimpleWayToImportIFCFiles')}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={10} justifyContent="center">
            <Grid
              container
              spacing={3}
              sx={({ spacing }: Theme) => ({
                paddingTop: spacing(5),
              })}
            >
              {cardContents.map(({ iconSrc, title, text }, index) => (
                <Grid item xs={4} key={title}>
                  <IconCard
                    iconSrc={iconSrc}
                    title={`${index + 1}. ${t(title as unknown as TemplateStringsArray)}`}
                    text={t(text as unknown as TemplateStringsArray)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} sx={({ palette }: Theme) => ({ background: palette.grey[800] })}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Stack
            py={3}
            px={2}
            spacing={2}
            sx={({ palette, shape }: Theme) => ({
              background: palette.grey[700],
              borderRadius: shape.borderRadius,
            })}
          >
            <Button onClick={() => mutate()} loading={isLoading} variant="contained">
              {t('actions.signIn')}
            </Button>
            <Button
              style={{ color: theme.palette.common.white }}
              component="a"
              target="_blank"
              href={modugenRegisterURL}
              variant="contained"
            >
              {t('actions.createAccount')}
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Authentication
