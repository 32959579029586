import { useEffect } from 'react'

import { useRoofStore } from '../store'

const useRegisterStoreSync = (points: Point[], replace: (points: Point[]) => void) => {
  const setPoints = useRoofStore(state => state.setPoints)
  const setUpdatePointsClb = useRoofStore(state => state.setUpdatePointsClb)

  useEffect(() => {
    // @ts-ignore
    setPoints(points)
    setUpdatePointsClb(replace)
  }, [points])
}

export default useRegisterStoreSync
