import { ReactElement, ReactNode, useMemo } from 'react'

import { Stack } from '@mui/material'

import { Form } from 'src/components/generic/forms'

import PointsDataGrid from '../../../internal/components/PointsDataGrid'
import SubmitButton from '../../../internal/components/SubmitButton'
import { generateDefaultValues } from '../../../internal/utils'
import { schema } from './schema'

interface Props {
  onSave?: (points: Point[]) => Promise<void>
  initialPoints: Point[]
  actions?: ReactNode
}

const RoofEditingForm = ({ actions, initialPoints, onSave }: Props): ReactElement => {
  const rows = useMemo(() => generateDefaultValues(initialPoints), [initialPoints])

  return (
    <Form
      onSubmit={({ rows }) => onSave?.(rows)}
      defaultValues={{ rows }}
      validationSchema={schema}
    >
      <Stack direction="column" spacing={2}>
        <PointsDataGrid initialPoints={rows} />

        <Stack display="flex" flex={1} justifyContent="flex-end" direction="row" spacing={1}>
          {actions}
          <SubmitButton />
        </Stack>
      </Stack>
    </Form>
  )
}

export default RoofEditingForm
