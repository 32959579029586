import { ReactElement, ChangeEvent } from 'react'

import { Switch, Typography, Box } from '@mui/material'

interface Props {
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label: string
  disabled?: boolean
  'data-cy'?: string
}

const SwitchLabeled = ({
  checked,
  onChange,
  label,
  disabled,
  'data-cy': dataCy,
}: Props): ReactElement => {
  return (
    <Box display="flex" alignItems="center">
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        size="small"
        data-cy={dataCy}
      />
      <Typography sx={{ p: 1 }}>{label}</Typography>
    </Box>
  )
}

export default SwitchLabeled
