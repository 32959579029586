import { useSnackbar } from 'notistack'
import { Vector3 } from 'three'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router'

import { Typography, Stack, Button } from '@mui/material'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { Toolbox } from 'src/components/generic/Toolbox'
import { ErrorField, Form } from 'src/components/generic/forms'
import { config } from 'src/config/config'
import { usePlanarWallsInStorey } from 'src/pages/IfcImporter/hooks/usePlanarWallsInStorey'
import { PlanarModel } from 'src/pages/IfcImporter/types'
import { projectPlanarWall } from 'src/pages/IfcImporter/utils/projectPlanarWall'
import { SimpleLine } from 'src/types'
import { postRequest } from 'src/utils/requests'

import { setCurrentModel } from '../../../../queries/getAndSetCurrentModel'
import { useEditModelStore } from '../../../../stores/editModelStore'
import FormFields from './FormFields'
import { DrawWallSchema, schema } from './schema'

function keyFromVector(v: Vector3 | ImmutableVector3): string {
  return `x${v.x}-y${v.y}-z${v.z}`
}

export function DrawWallSubTab(): ReactElement {
  const { t } = useTranslation(['common', 'step5Arch'])
  const { projectId } = useParams<{ projectId: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const activeStorey = useEditModelStore(state => state.activeStorey)

  const drawnWall = useEditModelStore(state => state.drawnWall)
  const setDrawnWall = useEditModelStore(state => state.setDrawnWall)

  const planarWallsInStorey = usePlanarWallsInStorey()

  const addWallMutation = useMutation(
    async (input: DrawWallSchema) => {
      const resp = await postRequest<PlanarModel>({
        url: config.apiRoutes.postAddWall(projectId),
        data: {
          p1: {
            x: input.startX,
            y: input.startY,
          },
          p1_connects_to: drawnWall?.startSnapGuid,
          p2: {
            x: input.endX,
            y: input.endY,
          },
          p2_connects_to: drawnWall?.endSnapGuid,
          storey_idx: activeStorey,
          placement: input.placement,
        },
      })
      return resp.data
    },
    {
      onSuccess: currenModelResponse => {
        setDrawnWall(undefined)
        setCurrentModel(currenModelResponse)
      },
      onError: () => {
        enqueueSnackbar(t('step5Arch:errors.addWall'), { variant: 'error' })
      },
    },
  )

  const planarWalls = useMemo<SimpleLine[]>(
    () =>
      planarWallsInStorey.map(wall => {
        const points = projectPlanarWall(wall).points
        const start = points[0]
        const end = points[1]

        return {
          start: [start.x, start.y, 0],
          end: [end?.x, end.y, 0],
        }
      }) || [],
    [planarWallsInStorey],
  )

  const defaultValues = useMemo<DrawWallSchema | undefined>(
    () =>
      drawnWall
        ? {
            startX: drawnWall.start.x,
            startY: drawnWall.start.y,
            endX: drawnWall.end.x,
            endY: drawnWall.end.y,
            placement: drawnWall.placement,
          }
        : undefined,
    [drawnWall],
  )

  return (
    <>
      {drawnWall ? (
        <Form
          id="draw-wall"
          // workaround to force the form to rebuild when drawn wall is edited
          // in the scene
          key={`${keyFromVector(drawnWall.start)}+${keyFromVector(drawnWall.end)}`}
          onSubmit={addWallMutation.mutate}
          validationSchema={schema}
          validationContext={{
            planarWalls,
          }}
          defaultValues={defaultValues}
        >
          <Toolbox pt={3}>
            <FormFields />

            <ErrorField name="isIntersecting" />

            <Stack direction="column" spacing={1} overflow="hidden">
              <Button
                loading={addWallMutation.isLoading}
                variant="contained"
                type="submit"
                fullWidth
              >
                {t('step5Arch:editModelTab.addNewWall')}
              </Button>
            </Stack>
          </Toolbox>
        </Form>
      ) : (
        <Typography textAlign="center">{t('step5Arch:editModelTab.drawNewInnerWall')}</Typography>
      )}
    </>
  )
}
