import { filter, find, toNumber } from 'lodash-es'
import { Object3D, BufferGeometry, Line as ThreeLine } from 'three'

import React, { useEffect, ReactElement, ReactNode, useMemo } from 'react'

import { HighlightableLine } from '@modugen/scene/lib/components/Lines/HighlightableLine'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { Line } from '@react-three/drei'

import { DashedLine, HighlightableLineV2 } from 'src/components/shared/scene'
import sceneColors from 'src/styles/sceneColors'

import { usePlanarElementsInOtherStorey } from '../../hooks/usePlanarWallsInOtherStoreys'
import { usePlanarWallsInStorey } from '../../hooks/usePlanarWallsInStorey'
import { useEditModelStore } from '../../stores/editModelStore'
import { projectSlab } from '../../utils/projectSlab'
import { useGeneratedModelStore } from '../GeneratedModelController/generatedModelStore'
import useProjectedWalls from './useProjectedWalls'

interface Props {
  highlightedWalls?: string[]
  excludeGuids?: string[]
  onClickWall?: (wallId: string) => void
  onClickScene?: () => void
  wallsSelectable?: boolean
  children?: ReactNode
}

export function FloorPlanController({
  highlightedWalls = [],
  excludeGuids = [],
  wallsSelectable = false,
  onClickWall,
  onClickScene,
  children,
}: Props): ReactElement | null {
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const planarWallsInStorey = usePlanarWallsInStorey()
  const { walls: planarWallsInOtherStoreys } = usePlanarElementsInOtherStorey()

  const cameraRotationTarget = useCameraStore(state => state.rotationTarget)

  const setTapelineSnapTargets = useTapelineStore(state => state.setAdditionalSnapTargets)

  const setWallDrawingSnapTargets = useEditModelStore(state => state.setAdditionalSnapTargets)
  const visibleStoreys = useEditModelStore(state => state.visibleStoreys)
  const showSlabBoundaries = useEditModelStore(state => state.showSlabBoundaries)

  const activeStorey = useEditModelStore(state => state.activeStorey)

  // STOREY GEOMETRY RELATED

  const visibleSlabs = useMemo(
    () =>
      filter(currentModelPlanar?.slabs, slab => {
        const assignment = find(
          Object.entries(currentModelPlanar?.storey_assignment?.slab_storey_id_assignment || []),
          ([, elementsInStorey]) => elementsInStorey.includes(slab.guid),
        )

        const storey = assignment?.[0].toString()

        return !!(storey === activeStorey || (storey && visibleStoreys.has(storey)))
      }),
    [
      activeStorey,
      currentModelPlanar?.slabs,
      currentModelPlanar?.storey_assignment.slab_storey_id_assignment,
      visibleStoreys,
    ],
  )

  const roofEdges = useMemo(
    () => currentModelPlanar?.roof_slabs.map(slab => projectSlab(slab)),
    [currentModelPlanar?.roof_slabs],
  )

  const slabEdges = useMemo(
    () => (showSlabBoundaries ? visibleSlabs.map(slab => projectSlab(slab)) : []),
    [showSlabBoundaries, visibleSlabs],
  )

  const [projectedWalls, projectedOpenings] = useProjectedWalls(planarWallsInStorey, excludeGuids)
  const [projectedWallsInOther, projectedOpeningsInOther] = useProjectedWalls(
    planarWallsInOtherStoreys,
    excludeGuids,
  )

  // EFFECT HOOKS

  // ensure no snap targets are left on model remove
  useEffect(
    () => () => {
      setTapelineSnapTargets(undefined)
      setWallDrawingSnapTargets(undefined)
    },
    [setTapelineSnapTargets, setWallDrawingSnapTargets],
  )

  useEffect(() => {
    // snap targets need to be specifically set as we need to enable snapping to
    // close elements without actually hovering them (which is not the standard
    // snap behavior) due to the nature of the orthographic projection

    const snapTargets: Object3D[] = []

    // only snap to walls, ignore openings for now
    for (const wall of [...projectedWalls, ...projectedWallsInOther]) {
      const geometry = new BufferGeometry().setFromPoints(wall.points.map(p => p.v))
      const line = new ThreeLine(geometry)
      // used for identifying the wall when snapping to it
      line.userData.guid = wall.guid
      snapTargets.push(line)
    }

    setTapelineSnapTargets(snapTargets)
    setWallDrawingSnapTargets(snapTargets)

    return () => {
      setTapelineSnapTargets([])
      setWallDrawingSnapTargets([])
    }
  }, [projectedWalls, projectedWallsInOther, setTapelineSnapTargets, setWallDrawingSnapTargets])

  const storeyAsNumber = activeStorey === 'Dach' ? Number.MAX_SAFE_INTEGER : toNumber(activeStorey)

  // RENDER

  return (
    <>
      <group>
        {projectedWalls.map(wall => {
          const isExternal = wall.placement === 'External'
          const lineWidth = isExternal
            ? sceneColors.walls.external.lineWidth
            : sceneColors.walls.internal.lineWidth
          const color = isExternal
            ? sceneColors.elements2d.externalWalls
            : sceneColors.elements2d.internalWalls
          return (
            <HighlightableLine
              key={wall.guid}
              line={wall.points}
              color={color}
              cursor={wallsSelectable ? 'pointer' : 'auto'}
              isHighlighted={highlightedWalls.includes(wall.guid)}
              hoverable={wallsSelectable}
              clickable={wallsSelectable}
              onClick={() => onClickWall?.(wall.guid)}
              width={lineWidth}
            />
          )
        })}

        {/* slight elevation of 0.1 to put openings above the walls, different
            layer as openings should not be selectable */}
        <group position={[0, 0, 0.1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
          {projectedOpenings &&
            projectedOpenings.map(opening => {
              const isExternal = opening.placement === 'External'
              const lineWidth = isExternal
                ? sceneColors.walls.external.openings.lineWidth
                : sceneColors.walls.internal.openings.lineWidth
              return (
                <Line
                  key={opening.guid}
                  points={opening.pointsV}
                  color={sceneColors.elements2d.openings}
                  lineWidth={lineWidth}
                />
              )
            })}
        </group>
      </group>

      {/* Roof edges */}

      <group visible={visibleStoreys.has('Dach')}>
        {roofEdges?.map(roofSlab => (
          <group key={roofSlab.guid}>
            {roofSlab.points.map(([start, end]) => (
              <DashedLine
                key={`${start.x},${start.y},${start.z}-${end.x},${end.y},${end.z}`}
                points={[start.v, end.v]}
                color={sceneColors.elements2d.roofSlabs}
                lineWidth={2}
                opacity={1.0}
              />
            ))}
          </group>
        ))}
      </group>

      <group visible={showSlabBoundaries}>
        {slabEdges?.map(slab => {
          return (
            <group key={slab.guid}>
              {slab.points.map(([start, end]) => (
                <DashedLine
                  key={`${start.x},${start.y},${start.z}-${end.x},${end.y},${end.z}`}
                  points={[start.v, end.v]}
                  color={sceneColors.elements2d.slabs}
                  lineWidth={2}
                  opacity={1.0}
                />
              ))}
            </group>
          )
        })}
      </group>

      {/* Walls in other storeys  */}

      <group>
        {projectedWallsInOther.map(wall => {
          const isBelow = storeyAsNumber > toNumber(wall.storey)
          const isAbove = storeyAsNumber < toNumber(wall.storey)
          const color = isBelow
            ? sceneColors.elementsBelowActiveStorey
            : sceneColors.elementsAboveActiveStorey
          const isExternal = wall.placement === 'External'
          const lineWidth = isExternal
            ? sceneColors.walls.external.lineWidth
            : sceneColors.walls.internal.lineWidth
          const dashed = !!isAbove
          const dashSize = isAbove ? 0.05 : 0
          const gapSize = isAbove ? 0.05 : 0
          return (
            <HighlightableLineV2
              key={wall.guid}
              points={wall.points.map(p => p.v)}
              color={color}
              linewidth={lineWidth}
              dashed={dashed}
              dashSize={dashSize}
              gapSize={gapSize}
              onClick={() => onClickWall?.(wall.guid)}
              hoverCursor={wallsSelectable ? 'pointer' : 'auto'}
              isHighlighted={highlightedWalls.includes(wall.guid)}
              hoverable={wallsSelectable}
              clickable={wallsSelectable}
            />
          )
        })}

        {/* slight elevation of 0.1 to put openings above the walls, different
            layer as openings should not be selectable */}
        <group position={[0, 0, 0.1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
          {projectedOpeningsInOther &&
            projectedOpeningsInOther.map(opening => {
              const isExternal = opening.placement === 'External'
              const isAbove = storeyAsNumber < toNumber(opening.storey)
              const dashed = !!isAbove
              const dashSize = isAbove ? 0.15 : 0
              const gapSize = isAbove ? 0.05 : 0
              const lineWidth = isExternal
                ? sceneColors.walls.external.openings.lineWidth
                : sceneColors.walls.internal.openings.lineWidth
              return (
                <Line
                  key={opening.guid}
                  points={opening.pointsV}
                  color={sceneColors.elements2d.openings}
                  lineWidth={lineWidth}
                  dashed={dashed}
                  dashSize={dashSize}
                  gapSize={gapSize}
                />
              )
            })}
        </group>
      </group>

      {/* invisible plane serves as a hit target for the draw controller */}
      <mesh
        position={[cameraRotationTarget.x, cameraRotationTarget.y, -0.1]}
        onPointerDown={event => event.nativeEvent.button === 0 && onClickScene?.()}
      >
        <planeGeometry args={[100, 100]} />
        <meshStandardMaterial transparent opacity={0} />
      </mesh>

      {children}
    </>
  )
}
