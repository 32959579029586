import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, Theme, Typography } from '@mui/material'

import { TextField } from 'src/components/generic/forms'

export default function FormFields(): ReactElement {
  const { t } = useTranslation(['step5Arch'])

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.start')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startX"
          disabled
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="startY"
          disabled
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={(theme: Theme) => theme.spacing(5)} flexShrink={0}>
          {t('step5Arch:editModelTab.end')}:
        </Typography>

        <TextField
          inputLabel="X (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endX"
          disabled
        />

        <TextField
          inputLabel="Y (m)"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="endY"
          disabled
        />
      </Stack>
    </Stack>
  )
}
