export default {
  selection: '#eb4034',
  outlines: '#ffffff',
  outlinesDark: '#000000',

  elements3d: {
    walls: '#98a2ab',
    wallsExternal: '#7e8182',
    openings: '#3777ff',
    slabs: '#a6d0ff',
    roofs: '#340068',
    roof_slabs: '#340068',
    beams: '#f5c21b',
    columns: '#a5be00',
  },

  elements2d: {
    externalWalls: '#222',
    internalWalls: '#999999',
    openings: '#96BCE7',
    roofSlabs: '#340068',
    slabs: '#a6d0ff',
  },

  openings: {
    selected: '#16de52',
    removed: '#f03535',
  },

  issues: {
    error: '#f44336',
    warning: '#ffc400',
  },

  // for floorplan mode
  walls: {
    external: {
      lineWidth: 3,
      openings: {
        lineWidth: 3,
      },
    },
    internal: {
      lineWidth: 2,
      openings: {
        lineWidth: 2,
      },
    },
  },

  elementsAboveActiveStorey: '#FFA500',
  elementsBelowActiveStorey: '#497DE7',
}
