import produce from 'immer'
import cloneDeep from 'lodash-es/cloneDeep'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface HiddenElementIdsStoreState {
  isElementVisibilityMode: boolean
  hiddenElementIds: Set<string>
  hiddenElementIdsWithoutUserInteraction: Set<string>
}
const initialState: HiddenElementIdsStoreState = {
  isElementVisibilityMode: false,
  hiddenElementIds: new Set(),

  hiddenElementIdsWithoutUserInteraction: new Set(),
}

export const useHiddenElementsStore = create(
  combine(cloneDeep(initialState), set => ({
    setisElementVisibilityMode: (isElementVisibilityMode: boolean) =>
      set({
        isElementVisibilityMode,
      }),

    addHiddenElementIds: (hiddenObjectId: string[]) =>
      set(
        produce((state: HiddenElementIdsStoreState) => {
          state.hiddenElementIds = new Set([...hiddenObjectId, ...state.hiddenElementIds])
        }),
      ),

    deselectAllHiddenElementIds: () =>
      set(
        produce((state: HiddenElementIdsStoreState) => {
          state.hiddenElementIds.clear()
        }),
      ),

    setHiddenElementIdsWithoutUserInteraction: (hiddenObjectId: string[]) =>
      set(
        produce((state: HiddenElementIdsStoreState) => {
          state.hiddenElementIdsWithoutUserInteraction = new Set([...hiddenObjectId])
        }),
      ),
  })),
)
