import { MeshBasicMaterialParameters, DoubleSide } from 'three'

import React, { ReactElement, useCallback, useMemo } from 'react'

import { BasicMeshProps, BasicMesh } from '@modugen/scene/lib/components/BasicMesh'

import { useDerivedIfcDataStore } from 'src/pages/IfcImporter/stores/derivedIfcDataStore'
import { useHiddenElementsStore } from 'src/pages/IfcImporter/stores/hiddenElementsIdsStore'
import { useIfcElementsStore } from 'src/pages/IfcImporter/stores/ifcElementsStore'
import { useIssuesStore } from 'src/pages/IfcImporter/stores/issuesStore'
import sceneColors from 'src/styles/sceneColors'

import { onClickGltfModelListeners } from '../useOnClickGltfModel'

/**
 * we want to show the model a second time to display issues during operations
 * (e.g. parsing). As the coloring and the visibility is based on other logic
 * than selection we use this type of custom mesh
 */
export default function GltfIssueMesh({
  material,
  children,
  ...meshProps
}: BasicMeshProps): ReactElement | null {
  const ifcId = meshProps.name

  const isError = useIssuesStore(
    useCallback(
      state => !!state.errors.find(backendError => backendError.element_guid === ifcId),
      [ifcId],
    ),
  )

  const isWarning = useIssuesStore(
    useCallback(
      state => !!state.warnings.find(backendWarning => backendWarning.element_guid === ifcId),
      [ifcId],
    ),
  )

  const isIssueEmphasized = useIssuesStore(
    useCallback(state => state.emphasizedIssue?.element_guid === ifcId, [ifcId]),
  )

  const emphasizedIssueType = useIssuesStore(
    useCallback(
      state => {
        if (state.emphasizedIssue?.element_guid === ifcId) {
          if (state.warnings.find(backendWarning => backendWarning === state.emphasizedIssue))
            return 'warning'
          if (state.errors.find(backendWarning => backendWarning === state.emphasizedIssue))
            return 'error'
        }
      },
      [ifcId],
    ),
  )

  const relatedIfcElement = useDerivedIfcDataStore(
    useCallback(state => !!ifcId && state.searchableIfcElements[ifcId]?.ifcElement, [ifcId]),
  )

  const defaultColor =
    (material as MeshBasicMaterialParameters).color || sceneColors.elements3d.walls

  const newColor =
    (relatedIfcElement && sceneColors.elements3d[relatedIfcElement.PSET_data.MGroup]) ||
    defaultColor

  const color = useMemo(() => {
    if (isIssueEmphasized && emphasizedIssueType === 'error') return sceneColors.issues.error
    if (isIssueEmphasized && emphasizedIssueType === 'warning') return sceneColors.issues.warning

    if (isError) return sceneColors.issues.error
    if (isWarning) return sceneColors.issues.warning

    return newColor
  }, [isError, isWarning])

  // id of parent element if available
  const groupIfcId = useDerivedIfcDataStore(
    useCallback(state => ifcId && state.groupIdByChildId[ifcId], [ifcId]),
  )

  // element can get hidden alone or as part of its group parent
  const isHiddenByIfcStore = useIfcElementsStore(
    useCallback(
      state =>
        (!!ifcId && state.hiddenIfcIdsGltfModel.has(ifcId)) ||
        (!!groupIfcId && state.hiddenIfcIdsGltfModel.has(groupIfcId)),
      [ifcId, groupIfcId],
    ),
  )

  // element can get hidden alone or as part of its group parent
  const isHiddenByElementsStore = useHiddenElementsStore(
    useCallback(
      state =>
        (!!ifcId && state.hiddenElementIds.has(ifcId)) ||
        (!!groupIfcId && state.hiddenElementIds.has(groupIfcId)),
      [ifcId, groupIfcId],
    ),
  )

  const isTranslucent = useMemo(
    () => (isError || isWarning) && !isIssueEmphasized,
    [isError, isWarning, emphasizedIssueType],
  )

  return (
    <BasicMesh
      {...meshProps}
      cursor="pointer"
      visible={!isHiddenByIfcStore && !isHiddenByElementsStore}
      onClick={event =>
        Object.values(onClickGltfModelListeners).forEach(callback => callback(event))
      }
      // noPointerInteractions={isTapelineActive}
      renderOrder={isIssueEmphasized ? 1000 : undefined}
    >
      <meshStandardMaterial
        color={color}
        transparent={isTranslucent}
        opacity={isTranslucent ? 0.33 : 1}
        // polygonOffset is used to prevent rendering artifacts when gltfModel
        // elements are rendered in the same place as parsed model elements
        polygonOffset
        polygonOffsetUnits={isTranslucent ? 10 : 100}
        // required as some faces of some models are (most likely due to ifc
        // export issues) only visible from one side
        side={DoubleSide}
        depthTest={!isIssueEmphasized}
      />
      {children}
    </BasicMesh>
  )
}
