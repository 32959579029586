import { Vector2 } from 'three'

import { ReactElement, useState } from 'react'

import { config } from '@modugen/scene/lib/config'
import { Line, LineProps } from '@react-three/drei'

const lineResolution = new Vector2(1024, 1024)

interface Props extends LineProps {
  boundingLineProps?: Omit<LineProps, 'points'>
  hoverable?: boolean
  clickable?: boolean
  isHighlighted?: boolean
  hoverCursor?: string
}

const HighlightableLineV2 = ({
  boundingLineProps = { lineWidth: 16, opacity: 0.2 },
  lineWidth = 2,
  isHighlighted,
  hoverable,
  hoverCursor = 'pointer',
  clickable,
  ...props
}: Props): ReactElement => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <group>
      <Line
        {...boundingLineProps}
        points={props.points}
        color={props.color || boundingLineProps.color}
        transparent
        resolution={lineResolution}
        visible={!!((hoverable && isHovered) || isHighlighted)}
        onPointerOver={event => {
          event.stopPropagation()

          document.body.style.cursor = hoverCursor
          setIsHovered(true)
        }}
        onPointerOut={event => {
          event.stopPropagation()

          document.body.style.cursor = 'auto'
          setIsHovered(false)
        }}
        onClick={event => {
          event.stopPropagation()

          if (clickable) {
            props.onClick?.(event)
          }
        }}
      />

      <Line
        {...props}
        lineWidth={lineWidth}
        resolution={lineResolution}
        // the main line is just for show, all pointer interactions are
        // handled by the bounding line
        layers={config.R3FNonSelectableObjectLayer}
      />
    </group>
  )
}

export default HighlightableLineV2
