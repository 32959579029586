import { filter } from 'lodash-es'

import { useMemo } from 'react'

import { useGeneratedModelStore } from '../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../stores/editModelStore'

export function usePlanarElementsInOtherStorey() {
  const activeStorey = useEditModelStore(state => state.activeStorey)
  const visibleStoreys = useEditModelStore(state => state.visibleStoreys)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)
  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)

  const planarWallsInOtherStoreys = useMemo(
    () =>
      filter(
        currentModelPlanar?.walls.map(wall => ({
          ...wall,
          storey: currentModelStoreyByGuid[wall.guid],
        })),
        wall => {
          const elementStorey = currentModelStoreyByGuid[wall.guid]
          return elementStorey !== activeStorey && visibleStoreys.has(elementStorey)
        },
      ),
    [currentModelPlanar?.walls, currentModelStoreyByGuid, activeStorey, visibleStoreys],
  )

  const planarElements = useMemo(
    () => ({
      walls: planarWallsInOtherStoreys,
    }),
    [planarWallsInOtherStoreys],
  )

  return planarElements
}
