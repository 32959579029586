import { array, number, object } from 'yup'

const point = object({
  x: number().required(),
  y: number().required(),
  z: number().required(),
})

const points = array().of(point)

export const schema = object({
  rows: points,
})
