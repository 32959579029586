import { isUndefined } from 'lodash-es'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Cameraswitch from '@mui/icons-material/Cameraswitch'
import { Stack, Tooltip } from '@mui/material'

import { useCameraStore } from '@modugen/scene/lib'

import { HeaderToggleButton } from 'src/components/generic/HeaderToggleButtonGroup'
import useStoreHotkey from 'src/hooks/useStoreHotkey'

import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import { useEditModelStore } from '../../stores/editModelStore'
import useStoreySelection from '../../views/step5-Arch/hooks/useStoreySelection'

export function CameraControls(): ReactElement {
  const { t } = useTranslation('ifcImporter')

  const currentModelStoreyByGuid = useGeneratedModelStore(state => state.currentModelStoreyByGuid)

  const activeStorey = useEditModelStore(state => state.activeStorey)
  const selectedWall = useEditModelStore(state => state.selectedWall)

  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  const selectedWallStorey = selectedWall ? currentModelStoreyByGuid[selectedWall] : undefined

  const chooseStorey = useStoreySelection()

  useEffect(() => {
    if (!isUndefined(activeStorey)) {
      setIsOrthographic(true)
    }
  }, [activeStorey, setIsOrthographic])

  const activeDrawingStorey = useEditModelStore(state => state.activeStorey)

  const toggleFloorplan = useCallback(
    () => chooseStorey(isUndefined(activeDrawingStorey) ? selectedWallStorey || '0' : undefined),
    [activeDrawingStorey, chooseStorey, selectedWallStorey],
  )

  useStoreHotkey({
    keys: 'o',
    callback: toggleFloorplan,
    description: `Grundrissansicht an/ausschalten`,
    deps: [toggleFloorplan],
  })

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={t('camera.perspectiveSwitch')}>
        <HeaderToggleButton
          value="snap"
          size="small"
          onClick={toggleFloorplan}
          selected={!isUndefined(activeDrawingStorey)}
        >
          <Cameraswitch fontSize="small" />
        </HeaderToggleButton>
      </Tooltip>
    </Stack>
  )
}
