import { isNull, reject, round } from 'lodash-es'
import { enqueueSnackbar } from 'notistack'
import { Plane } from 'three'

import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import {
  DrawController,
  InteractiveLine,
  InteractiveLineHandles,
  InteractiveLineRef,
  ScalingIndicator,
  TransientDrawState,
} from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import TargetPlane from '../../../internal/components/TargetPlane'
import { roofDrawingDecimals, roofExtendingZPrecision } from '../../../internal/constants'
import useSelectRow from '../../../internal/hooks/useSelectRow'
import { useRoofStore } from '../../../internal/store'
import {
  generateDefaultValues,
  get2DLineOrthoDirection,
  infiniteLineIntersection,
  nthLooped,
  toVector2,
  projectPointOntoPlane,
} from '../../../internal/utils'

const inactiveColor = '#707070'

interface Props {
  onEditStart?: () => void
  onEditEnd?: () => void
}

const RoofEditingScene = ({ onEditStart, onEditEnd }: Props): ReactElement => {
  const points = useRoofStore(state => state.points)
  const cellSelection = useRoofStore(state => state.cellSelection)
  const updatePoints = useRoofStore(state => state.updatePoints)

  const pointsV = useMemo(
    () =>
      points?.map(p => ({
        ...p,
        v: new ImmutableVector3(p.x, p.y, p.z),
      })),
    [points],
  )

  const roofPlane = useMemo(
    () =>
      pointsV
        ? new Plane().setFromCoplanarPoints(pointsV[0].v.v, pointsV[1].v.v, pointsV[2].v.v)
        : undefined,
    [pointsV],
  )

  const roofEdges = useMemo(
    () =>
      pointsV
        ? pointsV.map((p, i) => {
            const nextP = nthLooped(pointsV, i + 1)

            return [p, nextP]
          })
        : undefined,
    [pointsV],
  )

  const edgeLineRefs = useRef<Array<InteractiveLineRef>>([])

  const [activeEdgeIndex, setActiveEdgeIndex] = useState<null | number>(null)

  useEffect(() => {
    if (!isNull(activeEdgeIndex)) {
      onEditStart?.()
    }

    return () => onEditEnd?.()
  }, [activeEdgeIndex, onEditEnd, onEditStart])

  const selectedPoint = useMemo(() => {
    const keys = Object.keys(cellSelection)
    if (keys.length) return keys[0]
  }, [cellSelection])

  const selectRow = useSelectRow()

  // EVENTS
  const moveRoofEdge = (state: TransientDrawState) => {
    if (isNull(activeEdgeIndex) || !state.drawPoint || !roofPlane) return

    const currentEdges = reject(edgeLineRefs.current, edge => !edge)

    // What we basically do here is use the current line to create a plane that
    // is vertical and extend the previous and next line to find any
    // intersection point on the plane to update the corner points of the roof
    const edge = currentEdges[activeEdgeIndex]
    if (!edge.transientLine.current) return
    if (roofEdges) {
      const [edgeStart, edgeEnd] = roofEdges[activeEdgeIndex]
      const edgeOrthogonalDirection2 = get2DLineOrthoDirection(
        toVector2(edgeStart),
        toVector2(edgeEnd),
      )
      const edgeOrthogonalDirection = new ImmutableVector3(
        edgeOrthogonalDirection2.x,
        edgeOrthogonalDirection2.y,
      )
      const cuttingPlane = new Plane().setFromNormalAndCoplanarPoint(
        edgeOrthogonalDirection.v,
        state.drawPoint.v,
      )
      const [previousEdgeStart, previousEdgeEnd] = nthLooped(roofEdges, activeEdgeIndex - 1)
      const [nextEdgeStart, nextEdgeEnd] = nthLooped(roofEdges, activeEdgeIndex + 1)
      const intersectionPrevious = infiniteLineIntersection(
        cuttingPlane,
        previousEdgeStart.v,
        previousEdgeEnd.v,
      )
      const intersectionNext = infiniteLineIntersection(
        cuttingPlane,
        nextEdgeStart.v,
        nextEdgeEnd.v,
      )
      if (intersectionPrevious && intersectionNext) {
        // In order to pass the checks by the backend the point must be
        // projected exactly onto the plane defined by the existing points of
        // the roof slab
        const intersectionPreviousProjected = projectPointOntoPlane(
          roofPlane,
          new ImmutableVector3(
            round(intersectionPrevious.x, roofDrawingDecimals),
            round(intersectionPrevious.y, roofDrawingDecimals),
            intersectionPrevious.z,
          ),
        )

        const intersectionNextProjected = projectPointOntoPlane(
          roofPlane,
          new ImmutableVector3(
            round(intersectionNext.x, roofDrawingDecimals),
            round(intersectionNext.y, roofDrawingDecimals),
            intersectionNext.z,
          ),
        )

        // We will round z here in order to have similar z for start and end
        const intersectionPreviousProjectedRounded = new ImmutableVector3(
          intersectionPreviousProjected.x,
          intersectionPreviousProjected.y,
          round(intersectionPreviousProjected.z, roofExtendingZPrecision),
        )

        const intersectionNextProjectedRounded = new ImmutableVector3(
          intersectionNextProjected.x,
          intersectionNextProjected.y,
          round(intersectionNextProjected.z, roofExtendingZPrecision),
        )
        const previousLineRef = nthLooped(currentEdges, activeEdgeIndex - 1)
        if (intersectionPrevious) {
          previousLineRef.updateHandle(
            InteractiveLineHandles.End,
            intersectionPreviousProjectedRounded,
          )
        }
        const nextLineRef = nthLooped(currentEdges, activeEdgeIndex + 1)
        if (intersectionNext) {
          nextLineRef.updateHandle(InteractiveLineHandles.Start, intersectionNextProjectedRounded)
        }
        edge.updateLine([intersectionPreviousProjectedRounded, intersectionNextProjectedRounded])
      } else {
        enqueueSnackbar(
          'Ecke kann nicht bewegt werden (wahrscheinlich sind mehrere Punkte auf derselben Kante)',
          {
            variant: 'info',
          },
        )
        setActiveEdgeIndex(null)
      }
    }
  }

  const drawEnd = () => {
    if (!roofEdges || isNull(activeEdgeIndex)) return
    const currentEdges = reject(edgeLineRefs.current, edge => !edge)

    const roofPoints = currentEdges.map(line => {
      if (line?.transientLine.current) {
        return line.transientLine.current[0]
      }
      return roofEdges[activeEdgeIndex][0]
    })
    updatePoints?.(generateDefaultValues(roofPoints.map(p => ({ x: p.x, y: p.y, z: p.z }))))

    setActiveEdgeIndex(null)
  }

  useEffect(() => {
    edgeLineRefs.current = []
  }, [roofEdges])

  if (isNull(points)) return <></>

  return (
    <>
      {/* DrawController used for moving the edges of an existing roof shape */}
      <DrawController
        enabled={!isNull(activeEdgeIndex)}
        onMouseMove={moveRoofEdge}
        onDrawEnd={drawEnd}
        enableIndicator={false}
        isValidDrawTarget={object => object.name === 'draw-plane'}
      />

      <group visible={isNull(activeEdgeIndex)}>
        {points.map(p => {
          return (
            <ScalingIndicator
              // @ts-ignore
              key={p.pId}
              position={[p.x, p.y, p.z]}
              color={selectedPoint === p.pId ? 'blue' : inactiveColor}
              opacity={selectedPoint === p.pId ? undefined : 0.5}
              onClick={() => selectRow(p.pId)}
            />
          )
        })}
      </group>

      <group key={JSON.stringify(roofEdges)}>
        {roofEdges?.map(([start, end], i) => (
          <InteractiveLine
            key={`${start.pId}-${end.pId}`}
            ref={element => {
              if (element) {
                edgeLineRefs.current[i] = element as InteractiveLineRef
              } else {
                delete edgeLineRefs.current[i]
              }
            }}
            line={[start.v, end.v]}
            color={i === activeEdgeIndex ? 'blue' : inactiveColor}
            disableDepthTest
            isSelected={activeEdgeIndex === i}
            onClick={() => setActiveEdgeIndex(i)}
            showHandles={false}
            nonSelectable={!isNull(activeEdgeIndex)}
            clickDisabled={!isNull(activeEdgeIndex)}
          />
        ))}
      </group>

      {pointsV && <TargetPlane points={pointsV.map(p => p.v)} />}
    </>
  )
}

export default RoofEditingScene
