import create from 'zustand'

import { GridCellSelectionModel } from '@mui/x-data-grid-premium'

type Point = {
  pId: string
  x: number
  y: number
  z: number
}

type UpdatePointsClbk = (points: Point[]) => void

type Store = {
  updatePoints: UpdatePointsClbk | null
  setUpdatePointsClb: (updatePoints: UpdatePointsClbk | null) => void
  points: Point[] | null
  setPoints: (points: Point[]) => void
  cellSelection: GridCellSelectionModel
  setCellSelection: (selectedPoint: GridCellSelectionModel) => void
}

export const useRoofStore = create<Store>(set => ({
  updatePoints: null,
  setUpdatePointsClb: (updatePoints: UpdatePointsClbk | null) => set({ updatePoints }),

  points: null,
  setPoints: (points: Point[]) => set({ points }),

  cellSelection: {},
  setCellSelection: (cellSelection: GridCellSelectionModel) => set({ cellSelection }),
}))
