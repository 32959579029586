import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import FileCopy from '@mui/icons-material/FileCopy'
import { Stack, Typography, Button } from '@mui/material'

import { CustomSidebar } from 'src/components/generic/CustomSidebar'

import { GeneratedModelController } from '../../controllers/GeneratedModelController'
import { useGeneratedModelStore } from '../../controllers/GeneratedModelController/generatedModelStore'
import { useShowOpenings } from '../../controllers/OpeningsControllers/openingsStore'
import { useDerivedIfcData } from '../../hooks/useDerivedIfcData'
import { useDerivedIfcDataStore } from '../../stores/derivedIfcDataStore'
import { useIfcElementsStore } from '../../stores/ifcElementsStore'
import { useProjectStore } from '../../stores/projectStore'
import {
  useTypeVisibilityStoreByInstance,
  getHiddenIdsFromTypes,
  getHiddenIdsFromPlanarModel,
} from '../../stores/typeVisibilityStore'

export function SidebarLeft(): ReactElement {
  return <></>
}

export function Scene(): ReactElement | null {
  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  const { ifcIdsByType, notExportedIds } = useDerivedIfcData()
  const trueIfcGroups = useDerivedIfcDataStore(state => state.trueIfcGroups)
  const mergedFilteredIds = useDerivedIfcDataStore(state => state.mergedFilteredIds)
  useShowOpenings(false)

  const setHiddenIdsGltfModel = useIfcElementsStore(state => state.setHiddenIdsGltfModel)
  const gltfVisibilityByType = useTypeVisibilityStoreByInstance(
    'gltf',
    'header',
  )(state => state.visibilityByType)

  const setHiddenIdsCurrentModel = useIfcElementsStore(state => state.setHiddenIdsCurrentModel)
  const currentVisibilityByType = useTypeVisibilityStoreByInstance(
    'current',
    'header',
  )(state => state.visibilityByType)

  useEffect(() => {
    setHiddenIdsGltfModel(
      new Set([
        ...getHiddenIdsFromTypes(ifcIdsByType, gltfVisibilityByType, trueIfcGroups),
        ...notExportedIds,
        ...mergedFilteredIds,
      ]),
    )

    if (currentModelPlanar) {
      setHiddenIdsCurrentModel(
        new Set(getHiddenIdsFromPlanarModel(currentVisibilityByType, currentModelPlanar)),
      )
    }
  }, [ifcIdsByType, gltfVisibilityByType, currentVisibilityByType])

  return <GeneratedModelController />
}

export function SidebarRight(): ReactElement {
  const { t } = useTranslation(['common', 'step6Export'])
  const { projectId } = useParams<{ projectId: string }>()
  const project = useProjectStore(state => state.project)

  const currentModelPlanar = useGeneratedModelStore(state => state.currentModelPlanar)

  return (
    <CustomSidebar
      title={t('step6Export:labels.exportToStatik')}
      bottom={
        <Stack spacing={2}>
          <Button
            fullWidth
            href={`data:text/json;chatset=utf-8,${encodeURIComponent(
              JSON.stringify(currentModelPlanar),
            )}`}
            download={`${project?.name.replace(/\s+/g, '')}-${projectId}.json`}
            disabled={!currentModelPlanar}
            variant="contained"
            startIcon={<FileCopy />}
          >
            {t('step6Export:actions.downloadJson')}
          </Button>
        </Stack>
      }
    >
      <Typography>{t('step6Export:hints.modelExport')}</Typography>
    </CustomSidebar>
  )
}
