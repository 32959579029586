import produce from 'immer'
import {
  find,
  findIndex,
  inRange,
  isNull,
  isNumber,
  isUndefined,
  nth,
  reject,
  slice,
  toNumber,
} from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Plane } from 'three'

import { ReactElement, useCallback, useMemo, MouseEvent, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useLatest } from 'react-use'

import {
  VerticalAlignTop,
  VerticalAlignBottom,
  DeleteOutline,
  Restore,
  ChangeHistory,
  RotateLeft,
  SwapVert,
} from '@mui/icons-material'
import { IconButton, Typography, Popover, Box, Stack, Divider, Alert } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-premium'

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

import { roofPlaneThreshold } from '../../constants'
import useRegisterStoreSync from '../../hooks/useRegisterStoreSync'
import useSelectRow from '../../hooks/useSelectRow'
import { useRoofStore } from '../../store'
import { sortPointsCounterClockwise, projectPointOntoPlane, toVector3 } from '../../utils'
import StyledDataGrid, {
  styledDataGridErrorClass,
  styledDataGridWarningClass,
} from '../StyledDataGrid'
import Toolbar from '../Toolbar'
import { createCoordinateNumberInput, getCenterPoint, isNearButNotEqual } from './utils'

interface Props {
  initialPoints: Point[]
}

const PointsDataGrid = ({ initialPoints }: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const cellSelection = useRoofStore(state => state.cellSelection)
  const setCellSelection = useRoofStore(state => state.setCellSelection)

  const selectRow = useSelectRow()

  const {
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext()

  const { fields, remove, insert, move, replace } = useFieldArray({ name: 'rows' })

  const rows: (Point & { pId: string })[] = fields as unknown as (Point & { pId: string })[]

  const rowsRef = useLatest(rows)

  useRegisterStoreSync(rows, replace)

  const deleteAction = useCallback(
    (id: string) => {
      if (rowsRef.current.length <= 3) {
        enqueueSnackbar('Es ist nicht möglich, weniger als 3 Punkte festzulegen', {
          variant: 'warning',
        })
        return
      }

      const index = findIndex(rowsRef.current, row => row.pId === id)
      remove(index)
      selectRow(rowsRef.current[index + 1].pId)
    },
    [rowsRef, remove, selectRow, enqueueSnackbar],
  )

  const insertAfterAction = useCallback(
    (id: string) => {
      const index = findIndex(rowsRef.current, row => row.pId === id)

      const currentPoint = rowsRef.current[index] as unknown as Point
      const nextPoint = nth(
        rowsRef.current,
        (index + 1) % rowsRef.current.length,
      ) as unknown as Point

      const newPoint = getCenterPoint(currentPoint, nextPoint)

      selectRow(newPoint.pId)

      insert(index + 1, newPoint)
    },
    [insert, rowsRef, selectRow],
  )

  const insertBeforeAction = useCallback(
    (id: string) => {
      const index = findIndex(rowsRef.current, row => row.pId === id)

      const beforePoint = nth(rowsRef.current, index - 1) as unknown as Point
      const currentPoint = rowsRef.current[index] as unknown as Point

      const newPoint = getCenterPoint(beforePoint, currentPoint)

      selectRow(newPoint.pId)

      insert(index, newPoint)
    },
    [insert, rowsRef, selectRow],
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'x',
        headerName: 'X',
        disableColumnMenu: true,
        sortable: false,
        disableReorder: true,
        type: 'number',
        editable: true,
        renderEditCell: createCoordinateNumberInput('x'),
        valueFormatter: (value?: number) =>
          isNumber(value) ? value.toLocaleString('de') : value || '',
      },
      {
        field: 'y',
        headerName: 'Y',
        disableColumnMenu: true,
        sortable: false,
        disableReorder: true,
        type: 'number',
        editable: true,
        renderEditCell: createCoordinateNumberInput('y'),
        valueFormatter: (value?: number) =>
          isNumber(value) ? value.toLocaleString('de') : value || '',
      },
      {
        field: 'z',
        headerName: 'Z',
        disableColumnMenu: true,
        sortable: false,
        disableReorder: true,
        type: 'number',
        editable: true,
        renderEditCell: createCoordinateNumberInput('z'),
        valueFormatter: (value?: number) =>
          isNumber(value) ? value.toLocaleString('de') : value || '',
      },
      {
        field: 'actions',
        headerName: 'Aktionen',
        width: 150,
        renderCell: params => (
          <div>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                insertBeforeAction(params.id as string)
              }}
            >
              <VerticalAlignTop fontSize="small" />
            </IconButton>

            <IconButton
              onClick={e => {
                e.stopPropagation()
                insertAfterAction(params.id as string)
              }}
            >
              <VerticalAlignBottom fontSize="small" />
            </IconButton>

            <IconButton
              onClick={e => {
                e.stopPropagation()
                deleteAction(params.id as string)
              }}
            >
              <DeleteOutline fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    ],
    [deleteAction, insertAfterAction, insertBeforeAction],
  )

  const onPressRotate = useCallback(() => {
    const valuesSorted = sortPointsCounterClockwise(rows as unknown as Point[])

    replace(valuesSorted)
  }, [rows, replace])

  const onPressReset = useCallback(() => setValue('rows', initialPoints), [initialPoints, setValue])

  const currentRoofPlane = useMemo(
    () =>
      new Plane().setFromCoplanarPoints(
        toVector3(rows[0] as unknown as Point),
        toVector3(rows[1] as unknown as Point),
        toVector3(rows[2] as unknown as Point),
      ),
    [rows],
  )

  const onPressComputeZ = useCallback(() => {
    if (rows.length < 3) return

    const newRows = produce(draft => {
      const referenceRows = slice(draft, 0, 3)
      for (let i = 3; i < draft.length; i++) {
        const p = draft[i]
        const pV = new ImmutableVector3(toNumber(p.x), toNumber(p.y), toNumber(p.z))
        const projectedP = projectPointOntoPlane(currentRoofPlane, pV)

        const nearestReference = find(referenceRows, row => isNearButNotEqual(projectedP.z, row.z))
        if (!isUndefined(nearestReference)) {
          enqueueSnackbar(
            'Warnung, zwei Z-Koordinaten sind beinahe identisch, bitte manuell anpassen wenn erwünscht',
            { variant: 'warning' },
          )
        }

        draft[i].z = projectedP.z
      }
    }, rows)()

    replace(newRows)
  }, [rows, replace, currentRoofPlane, enqueueSnackbar])

  const onChangeOrder = useCallback(() => {
    move(rows.length - 1, 0)
  }, [rows, move])

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onHelp = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  return (
    <div style={{ width: '100%' }}>
      <StyledDataGrid
        getRowId={row => row.pId}
        ignoreValueFormatterDuringExport
        rows={rows}
        columns={columns}
        cellSelection
        autosizeOnMount
        // @ts-expect-error
        slots={{ toolbar: Toolbar }}
        slotProps={{
          toolbar: {
            onReset: onPressReset,
            // @ts-ignore
            onRotate: onPressRotate,
            onPressComputeZ: onPressComputeZ,
            onChangeOrder: onChangeOrder,
            onHelp: onHelp,
          },
        }}
        density="compact"
        hideFooter
        processRowUpdate={newRow => {
          const index = findIndex(rows, row => row.pId === newRow.pId)

          if (index === -1) throw new Error('Row not found')

          const updatedRows = produce(draft => {
            draft[index] = newRow as Point & {
              pId: string
            }
          }, rows)()

          // update(index, newRow)
          setValue('rows', updatedRows)
          trigger()

          return newRow
        }}
        cellSelectionModel={cellSelection}
        onCellSelectionModelChange={state => setCellSelection(state)}
        disableMultipleRowSelection
        disableRowSelectionOnClick
        getCellClassName={params => {
          const currentRoofPlane = new Plane().setFromCoplanarPoints(
            toVector3(rows[0] as unknown as Point),
            toVector3(rows[1] as unknown as Point),
            toVector3(rows[2] as unknown as Point),
          )

          if ('rows' in errors && errors.rows !== undefined) {
            const index = findIndex(rowsRef.current, row => row.pId === params.row.pId)

            // @ts-expect-error
            const error = errors.rows[index]

            if (error && params.field in error) {
              return styledDataGridErrorClass
            }
          }

          const otherRows = reject(rows, row => row.pId === params.id)

          if (params.field === 'x') {
            const currentX = params.row.x
            const otherRowNearCurrentX = find(otherRows, row => isNearButNotEqual(currentX, row.x))
            if (otherRowNearCurrentX) return styledDataGridWarningClass
          }

          if (params.field === 'y') {
            const currentY = params.row.y
            const otherRowNearCurrentY = find(otherRows, row => isNearButNotEqual(currentY, row.y))
            if (otherRowNearCurrentY) return styledDataGridWarningClass
          }

          if (params.field === 'z') {
            const currentZ = params.row.z
            const otherRowNearCurrentZ = find(otherRows, row => isNearButNotEqual(currentZ, row.z))
            if (otherRowNearCurrentZ) return styledDataGridWarningClass

            // Check if z is in same plane
            const p = toVector3(params.row)
            const distance = currentRoofPlane.distanceToPoint(p)
            if (!inRange(distance, -roofPlaneThreshold, roofPlaneThreshold)) {
              return styledDataGridWarningClass
            }
          }

          return ''
        }}
        rowReordering
        onRowOrderChange={change => {
          move(change.oldIndex, change.targetIndex)
        }}
      />

      <Popover
        open={!isNull(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack p={2} direction="column" spacing={3} maxWidth={500}>
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Zurücksetzen</Typography>
              <Restore fontSize="small" />
            </Stack>
            <Typography>Daten auf den ursprünglichen Zustand zurücksetzen</Typography>
          </Box>

          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Punkte gegen Uhrzeigersinn sortieren</Typography>
              <RotateLeft fontSize="small" />
            </Stack>
            <Typography>
              Dies ist hilfreich, wenn die Reihenfolge der Punkte durcheinander ist. Hier wird
              versucht die Reihenfolge automatisch sinnvoll in die richtige Ordnung zu bringen.
            </Typography>
          </Box>

          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Z Angleichen</Typography>
              <ChangeHistory fontSize="small" />
            </Stack>
            <Typography>
              Hier wird anhand der <strong>ersten 3 Punkte</strong> eine Dachebene aufgespannt. Die
              restlichen Punkte werden dann vertikal verschoben, bis sie auf der Dachfläche
              aufliegen.
            </Typography>
          </Box>

          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Punkte rotieren</Typography>
              <SwapVert fontSize="small" />
            </Stack>
            <Typography>
              Rotiert die Punkte um 1. Dies kann hilfreich sein, bevor die Z-Angleichen Methode
              verwendet wird.
            </Typography>
          </Box>

          <Divider />

          <Box>
            <Typography variant="h6">Warnungen</Typography>

            <Stack direction="column" spacing={2}>
              <Typography>
                Felder, die orange angezeigt werden, sind als Warnung zu verstehen. Dies kann in den
                folgenden beiden Fällen auftreten:
              </Typography>

              <Alert color="warning">
                1. Die Felder sind anderen sehr ähnlich, das heißt, die Koordinaten liegen sehr nahe
                an einem anderen Punkt. Hier hilft es, in die Felder hineinzuspringen, um alle
                Nachkommastellen zu sehen. Das Speichern sollte dennoch möglich sein.
              </Alert>

              <Alert color="warning">
                2. Die Z-Koordinate scheint außerhalb der Dachfläche zu liegen, die über die ersten
                drei Punkte gespannt wird. Das kann bis zu einem gewissen Grad in Ordnung sein,
                könnte aber auch dazu führen, dass die Dachfläche nicht gespeichert werden kann.
              </Alert>
            </Stack>
          </Box>
        </Stack>
      </Popover>
    </div>
  )
}

export default PointsDataGrid
